/* Import component react */
import React, {useEffect, useMemo, useState} from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import style */
import {Card} from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import {Button, message, Popconfirm} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRecycle, faTrash} from "@fortawesome/free-solid-svg-icons";

/* Main Module */
function QuotationHistoryTrash() {
    /* Handle State */
    const [quotationHistory, setQuotationHistory] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);

    /* Handle Table */
    /*eslint-disable*/
    const columns = useMemo(() => [
        {
            accessorKey: "quotation_number",
            header: "Quotation #",
        },
        {
            accessorFn: (row) => dayjs(row.created_at).format('YYYY-MM-DD'),
            header: "Created At",
        },
        {
            accessorKey: "valid_until",
            header: "Valid Until",
        },
        {
            accessorKey: "status",
            header: "Status",
        },
        {
            accessorKey: "total_item",
            header: "Total Item",
        },
        {
            accessorKey: "quantity",
            header: "Quantity",
        },
        {
            accessorKey: "total_price",
            header: "Total Harga",
        },
        {
            accessorKey: "created_by",
            header: "Created By",
        },
        {
            accessorFn: (row) => dayjs(row.deleted_at).format('YYYY-MM-DD'),
            header: "Deleted At",
        }
    ]);


    /*
    * Fetch data in first render.
    */
    useEffect(() => {
        fetchData().finally();
    }, []);


    /*
    * Get data form backend.
    */
    async function fetchData() {
        setLoadingTable(true);
        await Api.get('index-quotation-trash').then(response => {
            setQuotationHistory(response.data.data);
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
        setLoadingTable(false);
    }


    /*
    * Delete quotation.
    */
    const confirmDelete = async (uuid, quotation_number) => {
        await Api.delete(`/index-quotation/${uuid}`).then(response => {
            message.success(`Quotation ${quotation_number} deleted`);
            console.log(response);
            fetchData();
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
    }


    /*
    * Restore sale form trash
    */
    const confirmRestore = async (uuid, quotation_number) => {
        await Api.get(`/index-quotation-restore/${uuid}`).then(response => {
            message.success(`Quotation ${quotation_number} is restored`);
            console.log(response);
            fetchData();
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loadingTable }}
                columns={columns}
                data={quotationHistory}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'quotation_number'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                renderRowActions={({ row}) => (
                    <>
                        <Popconfirm
                            title="Restore"
                            description="Are you sure to restore this?"
                            onConfirm={() => confirmRestore(row.original.uuid, row.original.quotation_number)}
                            onCancel={() => message.error('Restore Canceled')}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small"> <FontAwesomeIcon icon={faRecycle} /> </Button>
                        </Popconfirm>

                        <Popconfirm
                            title="Delete"
                            description="Are you sure to delete this?"
                            onConfirm={() => confirmDelete(row.original.uuid, row.original.quotation_number)}
                            onCancel={() => message.error('Delete Canceled')}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small"> <FontAwesomeIcon icon={faTrash} /> </Button>
                        </Popconfirm>
                    </>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '450px',
                        maxHeight: '650px'
                    }
                }}
            />
        </Card>
    )
}

export default QuotationHistoryTrash;