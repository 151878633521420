/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import child component */
import ReportSaleCustomer from "./ReportSaleCustomer";
import ReportSaleItem from "./ReportSaleItem";


/* Main component */
function ReportSaleNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Report Stock</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/report-sale" eventKey="/inventory/report-sale" active={location.pathname === "/inventory/report-sale"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Sale Customer
                        </Button>
                        <Button as={Link} to="/inventory/report-sale/item" eventKey="/inventory/report-sale/item" active={location.pathname === "/inventory/report-sale/item"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Sale Item
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<ReportSaleCustomer/>} />
                <Route path="item" element={<ReportSaleItem/>} />
            </Routes>
        </>
    );
}

export default ReportSaleNav;