/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import UsersView from "./UsersView";
import UsersCreate from "./UsersCreate";
import UsersUpdate from "./UsersUpdate";

/* Return the module*/
function UsersNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Data User</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/user" eventKey="/inventory/user" active={location.pathname === "/inventory/user"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                        <Button as={Link} to="/inventory/user/create" eventKey="/inventory/user/create" active={location.pathname === "/inventory/user/create"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Create
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<UsersView/>} />
                <Route path="create" element={<UsersCreate/>} />
                <Route path="update/:id" element={<UsersUpdate/>} />
            </Routes>
        </>
    );
}

export default UsersNav;