/* Import Component React */
import React, { useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../Api";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space} from "antd";

/* Import form */
import BarangForm from "./BarangForm";

/* Return the module */
function BarangUpdate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State message
    const [messageApi, contextHolder] = message.useMessage();
    //Defined navigate
    const navigate = useNavigate();
    //Get params id
    let { id } = useParams();

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `Barang ${value} is updated`,
        }).then(() => {
            onReset();
        });
    }

    //Error message
    const errorUpdate = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Connection to api for find data
    async function fetchData() {
        //Await
        return await Api.get(`/data-barang/${id}`);
        //...
    }

    //Hook data for form
    /* eslint-disable */
    useEffect(() => {
        fetchData().then(response => {
            //Get response data
            const doc = response.data.data

            //Assign doc to field
            formRef.current?.setFieldsValue({
                part_number: doc.part_number,
                nama_barang: doc.nama_barang,
                manufaktur_id: { value: doc.manufaktur_rel.uuid, label : doc.manufaktur_rel.nama_manufaktur },
                kategori_id: { value: doc.kategori_rel.uuid, label: doc.kategori_rel.nama_kategori },
                sub_kategori_id: { value: doc.sub_kategori_rel.uuid, label: doc.sub_kategori_rel.nama_sub_kategori },
                jenis_barang_id: { value: doc.jenis_barang_rel.uuid, label: doc.jenis_barang_rel.nama_jenis_barang },
                satuan_id: { value: doc.satuan_rel.uuid, label: doc.satuan_rel.uom },
                harga_jual: doc.harga_jual
            });
        }).catch(() => {
            cancel();
        })
    }, [id]);

    //Processing update document
    const updateBarang = async (values) => {
        //Prepare value options
        let manufaktur_id, kategori_id, sub_kategori_id, jenis_barang_id, satuan_id;
        values.manufaktur_id.value ? (manufaktur_id = values.manufaktur_id.value) : (manufaktur_id = values.manufaktur_id);
        values.kategori_id.value ? (kategori_id = values.kategori_id.value) : (kategori_id = values.kategori_id);
        values.sub_kategori_id.value ? (sub_kategori_id = values.sub_kategori_id.value) : (sub_kategori_id = values.sub_kategori_id);
        values.jenis_barang_id.value ? (jenis_barang_id = values.jenis_barang_id.value) : (jenis_barang_id = values.jenis_barang_id);
        values.satuan_id.value ? (satuan_id = values.satuan_id.value) : (satuan_id = values.satuan_id);

        //Send data to backend
        await Api.post(`/data-barang/${id}`, {
            part_number: values.part_number,
            nama_barang: values.nama_barang,
            manufaktur_id: manufaktur_id,
            kategori_id: kategori_id,
            sub_kategori_id: sub_kategori_id,
            jenis_barang_id: jenis_barang_id,
            satuan_id: satuan_id,
            harga_jual: values.harga_jual,
            _method: 'PUT',
        }).then(() => {
            success(values.nama_barang);
            setTimeout(() => {
                navigate('/inventory/data-barang')
            }, 1000);
        }).catch((error) => {
            errorUpdate(error.response.data.message);
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
    }

    //cancel and back to main menu
    const cancel = () => {
        navigate('/inventory/data-barang')
    }

    return (
        <Row className="justify-content-md-center">
            {contextHolder}
            <Col xs sm={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Update Data Barang
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card.Body>
                                <Form layout="vertical" onFinish={updateBarang} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-4">
                                    <BarangForm/>

                                    <hr className="my-3" />
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                            <Button onClick={cancel} htmlType="button">
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card.Body>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm={4}>
                {/*<Card>*/}
                {/*    <ListGroup variant="flush">*/}
                {/*        <ListGroup.Item className="header-form">Instruction</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">All Field is a Required Field</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Jenis Barang is Like Original, or OEM Item</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Harga Jual is a Price For Sale Product to Customer</ListGroup.Item>*/}
                {/*    </ListGroup>*/}
                {/*</Card>*/}
            </Col>
        </Row>
    );
}

export default BarangUpdate;