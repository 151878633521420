/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import SaleView from "./SaleView";
import SaleHistoryView from "./SaleHistoryView";
import SaleHistoryDetail from "./SaleHistoryDetail";
import SaleHistoryTrash from "./SaleHistoryTrash";

/* Return the module*/
function SaleNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Sale</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link} to="/inventory/sale" eventKey="/inventory/sale" active={location.pathname === "/inventory/sale"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Sale
                        </Button>
                        <Button as={Link}  to="/inventory/sale/history" eventKey="/inventory/sale/history" active={location.pathname === "/inventory/sale/history"} variant="outline-secondary" size="sm" className="btn-navigation">
                            History
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button as={Link} to="/inventory/sale/trash" eventKey="/inventory/sale/trash" active={location.pathname === "/inventory/sale/trash"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Trash
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<SaleView />} />
                <Route path="history" element={<SaleHistoryView />} />
                <Route path="detail/:uuid" element={<SaleHistoryDetail />} />
                <Route path="trash" element={<SaleHistoryTrash />} />
            </Routes>
        </>
    );
}

export default SaleNav;