/* Import component react */
import React, {useState, useCallback, useEffect} from "react";
import Api from "../Api";

/* Import style */
import {Form, Modal, message, Select, Input, InputNumber, Button, Skeleton} from "antd";
import {Col, Row} from "react-bootstrap";

/* Import child component */
import QuotationTableItem from "./QuotationTableItem";

/* Main Component */
function QuotationItemOperation(props) {
    /* Handle state */
    /*eslint-disable*/
    const loadingTable = props.loading;
    const quotationItem = props.quotationItem;
    const quotationUuid = props.quotationUuid;
    const margin = props.margin;
    const [formRefItem] = Form.useForm();
    const [listItem, setListItem] = useState([]);
    const [loadingListItem, setLoadingListItem] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingChildForm, setLoadingChildForm] = useState(false);
    const [openModalItem, setOpenModalItem] = useState(false);
    const [openModalUpdateItem, setOpenModalUpdateItem] = useState(false);
    const [uuidItem, setUuidItem] = useState(null);

    /*
    * Resetting modal if modal state is change.
    */
    useEffect(() => {
        onReset();
        /*eslint-disable*/
    }, [openModalItem, openModalUpdateItem]);


    /*
    * Handle Open Modal Item.
    */
    const handleOpenModalItem = () => {
        setOpenModalItem(true);
    }


    /*
    * Handle processing store item.
    */
    const storeItem = async (values) => {
        //Send data to backend
        await Api.post('/item-quotation', {
            index_quotation_id: quotationUuid,
            barang_id: values.barang_id,
            qty_out: values.qty_out,
            qty_fill: values.qty_fill,
            diskon: values.diskon,
            quotation_price: values.harga,
        }).then(() => {
            message.success("Item added");
            props.fetchItem();
            onReset();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error, please try again");
        });
    }


    /*
    * Handle validation data quantity.
    */
    const validateQuantity = () => {
        const diskon = formRefItem.getFieldValue('diskon')/100;
        const margin_tage = margin/100;
        const qty_out = formRefItem.getFieldValue('qty_out');
        const qty_fill = formRefItem.getFieldValue('qty_fill');
        const stock = formRefItem.getFieldValue('stock');
        const harga = formRefItem.getFieldValue('harga');
        let qty, sub_total, diskonPrice, marginPrice, total = 0;

        //Calculation
        qty = qty_out*qty_fill;
        sub_total = qty*harga;
        diskonPrice = sub_total*diskon;
        marginPrice = sub_total*margin_tage;

        console.log(margin);
        //Sub total
        total = sub_total+marginPrice-diskonPrice;

        //Set field
        formRefItem.setFieldValue( 'sub_total', total);

        //Show error if over quantity
        qty > stock &&
        Modal.error({
            content: 'Quantity over stock',
            onOk() {
                formRefItem.setFieldsValue({
                    qty_out: 0,
                    qty_fill: 1,
                    sub_total: 0,
                });
            }
        });
    };


    /*
    * Handle item list.
    */
    async function fetchListItem() {
        //Await
        setLoadingListItem(true);
        await Api.get('/data-barang/select').then(response => {
            setListItem(response.data.data);
        }).catch(error => {
            console.log(error);
            message.error("Invalid serever error, please try again");
            setListItem([]);
        });
        setLoadingListItem(false);
    }

    const handleListItem = useCallback(async () => {
        fetchListItem().finally();
        /*eslint-disable*/
    }, [listItem]);


    /*
    * Handle data item.
    */
    async function fetchDataItem(value){
        setLoadingChildForm(true);
        await Api.get(`/data-barang/index/${value}`).then(response => {
            const doc = response.data.data;
            //Assign to field
            formRefItem.setFieldsValue({
                stock: doc.stock,
                satuan: doc.satuan,
                harga: doc.harga,
                sub_total: doc.harga,
                qty_out: 1,
                qty_fill: 1,
                diskon: 0,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error, please try again");
            formRefItem.setFieldsValue({
                stock: null,
                satuan: null,
                harga: null,
                sub_total: null,
                qty_out: 0,
                qty_fill: 0,
                diskon: 0,
            });
        });
        setLoadingChildForm(false);
    }

    const dataItem = useCallback(async (value) => {
        fetchDataItem(value).finally();
    }, [listItem]);



    /*
    * Handle double click row.
    */
    const handleDoubleClick = (uuid) => {
        setUuidItem(uuid);
        fetchItem(uuid).finally();
        setOpenModalUpdateItem(true);
    }

    /*
    * Handle update data item, fetch data and update.
    */
    async function fetchItem(uuid) {
        setLoadingForm(true);
        await Api.get(`/item-quotation/${uuid}`).then(response => {
            const doc = response.data.data;
            //Assign to field
            formRefItem.setFieldsValue({
                barang_id: { value: doc.value, label: doc.label },
                satuan: doc.satuan,
                stock: doc.stock,
                harga: doc.harga,
                qty_out: doc.quantity,
                qty_fill: 1,
                diskon: doc.diskon,
                sub_total: doc.sub_total,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error, please try again later");
            onReset();
        });
        setLoadingForm(false);
    }

    const updateItem = async (values) => {
        //Prepare value options
        let barang_id;
        values.barang_id.value ? (barang_id = values.barang_id.value) : (barang_id = values.barang_id);

        //Send data to backend
        await Api.post(`/item-quotation/${uuidItem}`, {
            barang_id: barang_id,
            qty_out: values.qty_out,
            qty_fill: values.qty_fill,
            diskon: values.diskon,
            quotation_price: values.harga,
            _method: 'PUT',
        }).then(() => {
            props.fetchItem();
            setOpenModalUpdateItem(false);
            message.success("Item Updated");
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error, please try again later");
        });
        setUuidItem(null);
    }


    //Reset form
    const onReset = () => {
        formRefItem.resetFields();
        formRefItem.setFieldsValue({
            harga: 0,
            qty_fill: 1,
            diskon: 0,
            sub_total: 0,
        });
    }


    /*
    * Handle delete item.
    */
    const deleteItem =  async (uuid, nama_barang) => {
        await Api.delete(`item-quotation/${uuid}`).then(() => {
            message.success(`${nama_barang} is deleted`);
            props.fetchItem();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }

    return (
        <>
            {/*Modal add item*/}
            <Modal
                tittle="Add Item"
                open={openModalItem}
                onCancel={() => setOpenModalItem(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Footer*/
                ]}
            >
                <Form layout="vertical" onFinish={storeItem} form={formRefItem} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
                    <FormItem
                        handleList={{handleListItem, loadingListItem}}
                        dataItem={{dataItem, listItem}}
                        validation={validateQuantity}
                        reset={onReset}
                        loadingForm={loadingChildForm}
                    />
                </Form>
            </Modal>

            {/*Modal update item*/}
            <Modal
                tittle="Update Item"
                open={openModalUpdateItem}
                onCancel={() => setOpenModalUpdateItem(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Footer*/
                ]}
            >
                <Skeleton active loading={loadingForm}>
                    <Form layout="vertical" onFinish={updateItem} form={formRefItem} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
                        <FormItem
                            handleList={{handleListItem, loadingListItem}}
                            dataItem={{dataItem, listItem}}
                            validation={validateQuantity}
                            reset={onReset}
                            loadingForm={loadingChildForm}
                        />
                    </Form>
                </Skeleton>
            </Modal>

            {/*Handle Table Item*/}
            <QuotationTableItem
                loading={loadingTable}
                data={quotationItem}
                delete={deleteItem}
                doubleClick={handleDoubleClick}
                disableButton={props.disableButton}
                openModal={handleOpenModalItem}
            />
        </>
    )
}

export default QuotationItemOperation;

function FormItem(props){
    /* Defined state for props */
    const handleList = props.handleList;
    const dataItem = props.dataItem;

    return  (
        <Row>
            <Col xs sm lg={12}>
                <Form.Item label="Reference" name="barang_id" rules={[ {required: true, message: "Please select a reference"} ]}>
                    <Select placeholder="Select a reference" allowClear showSearch filterOption={
                        (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    } onClick={handleList.handleListItem} onFocus={handleList.handleListItem} loading={handleList.loadingListItem} onSelect={dataItem.dataItem} options={dataItem.listItem} />
                </Form.Item>
            </Col>

            <Skeleton active loading={props.loadingForm}>
                <Col xs sm lg={4}>
                    <Form.Item label="Satuan" name="satuan">
                        <Input disabled={true} className="text-dark"/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Stock" name="stock">
                        <Input disabled={true} className="text-dark"/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Harga Jual" name="harga">
                        <InputNumber
                            style={{ width: "100%" }}
                            readOnly={true}
                            prefix="Rp."
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>

                <Col xs sm lg={4}>
                    <Form.Item label="QTY" name="qty_out" rules={[
                        { required: true, message: "Qty ?" }
                    ]}>
                        <InputNumber min={0} onChange={props.validation} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="ISI" name="qty_fill" rules={[
                        { required: true, message: "Qty isi ?" }
                    ]}>
                        <InputNumber min={0} onChange={props.validation} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Diskon%" name="diskon">
                        <InputNumber onChange={props.validation} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>

                <Col xs sm lg={12}>
                    <Form.Item label="Sub Total" name="sub_total">
                        <InputNumber
                            style={{ width: "100%" }}
                            readOnly={true}
                            prefix="Rp."
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>
            </Skeleton>

            <hr />
            <Col xs sm lg={12}>
                <Button onClick={props.reset} htmlType="button" style={{ width: "49%", marginRight: "1%"}}>
                    Reset
                </Button>
                <Button type="primary" htmlType="submit" style={{ width: "49%", marginLeft: "1%"}}>
                    Submit
                </Button>
            </Col>
        </Row>
    )
}