/* Import Component React */
import React, { useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../Api";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space} from "antd";

/* Import form */
import UsersFormUpdate from "./UsersFormUpdate";

/* Return the module */
function UsersUpdate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State message
    const [messageApi, contextHolder] = message.useMessage();
    //Defined navigate
    const navigate = useNavigate();
    //Get params id
    let { id } = useParams();

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `User ${value} is updated`,
        }).then(() => {
            onReset();
        });
    }

    //Error message
    const errorUpdate = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Connection to api for find data
    async function fetchData() {
        //Await
        return await Api.get(`/user/${id}`);
        //...
    }

    //Hook data for form
    /* eslint-disable */
    useEffect(() => {
        fetchData().then(response => {
            //Get response data
            const doc = response.data.data

            //Assign doc to field
            formRef.current?.setFieldsValue({
                name: doc.name,
                contact: doc.contact,
                username: doc.username,
                level: doc.level,
                active_login: doc.active_login
            });
        }).catch(() => {
            cancel();
        })
    }, [id]);

    //Processing update document
    const updateUser = async (values) => {
        //Send data to backend
        await Api.post(`/user/${id}`, {
            name: values.name,
            contact: values.contact,
            username: values.username,
            password:  values.password,
            level: values.level,
            active_login: values.active_login,
            _method: 'PUT',
        }).then(() => {
            success(values.name);
            setTimeout(() => {
                navigate('/inventory/user')
            }, 1000);
        }).catch((error) => {
            errorUpdate(error.response.data.message);
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
    }

    //cancel and back to main menu
    const cancel = () => {
        navigate('/inventory/user')
    }

    return (
        <Row className="justify-content-md-center">
            {contextHolder}
            <Col xs sm={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Update Data User
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card.Body>
                                <Form layout="vertical" onFinish={updateUser} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-4">
                                    <UsersFormUpdate/>

                                    <hr className="my-3" />
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                            <Button onClick={cancel} htmlType="button">
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card.Body>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm={4}>
                {/*<Card>*/}
                {/*    <ListGroup variant="flush">*/}
                {/*        <ListGroup.Item className="header-form">Instruction</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">All Field is a Required Field</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Username is unique, can't duplicate</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Password must have min 6 character </ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Double click For change password and fill the field password </ListGroup.Item>*/}
                {/*    </ListGroup>*/}
                {/*</Card>*/}
            </Col>
        </Row>
    );
}

export default UsersUpdate;