import React from "react";
import CompanyInfo from "../ComponentPrint/CompanyInfo";

/*Import Style*/
import '../Print.css';
import {Card, CardGroup} from "react-bootstrap";

const DeliveryPrint = React.forwardRef((props, ref) => {
    const deliveryInfo = props.delivery.deliveryInfo
    const saleItem = props.delivery.saleItem


    return (
        <div ref={ref} className="print-page">
            <div className="body-page">
                <CompanyInfo /> {/*Header*/}
                <hr className="print-line"/>

                <div className="header">
                    <h1 className="header-h1">Delivery Order</h1>
                </div>
                <article>

                    {/*Customer Info*/}
                    <address>
                        <p style={{marginBottom: "0.5em", fontWeight: "bold", textDecoration: "underline"}}>
                            Delivery To :
                        </p>
                        <p style={{marginBottom: "0.25em"}}> {deliveryInfo.destination} </p>
                        <p style={{marginBottom: "0.5em"}}> {deliveryInfo.destination_contact} </p>

                        <p style={{marginBottom: "0.25em", fontWeight: "bold", textDecoration: "underline"}}>
                            Address :
                        </p>
                        <p> {deliveryInfo.destination_address} </p>
                    </address>

                    {/*Invoice Info*/}
                    <table className="main meta">
                        <tbody>
                            <tr>
                                <th><span>Delivery Number #</span></th>
                                <td><span>{deliveryInfo.do_number}</span></td>
                            </tr>
                            <tr>
                                <th><span>Origin</span></th>
                                <td><span>{deliveryInfo.origin}</span></td>
                            </tr>
                            <tr>
                                <th><span>Date</span></th>
                                <td><span>{deliveryInfo.delivery_date}</span></td>
                            </tr>
                            <tr>
                                <th><span>Delivery Via</span></th>
                                <td><span>{deliveryInfo.delivery_via}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="print-line"/>

                    {/*Item Detail*/}
                    <table className="main inventory" style={{ breakInside: "auto"}}>
                        <thead>
                            <tr>
                                <th width="5%"><span>#</span></th>
                                <th width="20%"><span>Nama Barang</span></th>
                                <th width="20%"><span>Part Number</span></th>
                                <th width="15%"><span>Manufaktur</span></th>
                                <th width="10%"><span>Satuan</span></th>
                                <th width="10%"><span>Qty</span></th>
                                <th width="20%"><span>Invoice #</span></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            saleItem.length > 0 ?
                                saleItem.map((data, index) => (
                                    <tr>
                                        <td><span>{ index+1 }</span></td>
                                        <td><span>{ data.nama_barang }</span></td>
                                        <td><span>{ data.part_number }</span></td>
                                        <td><span>{ data.manufaktur }</span></td>
                                        <td><span>{ data.satuan }</span></td>
                                        <td><span>{ data.stock_out }</span></td>
                                        <td><span>{ data.invoice_number }</span></td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="alert alert-info mb-0">
                                                Item tidak tersedia
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>

                    {/*Item Total Info*/}
                    {/*<table className="main item" style={{ breakInside: "avoid" }}>*/}
                    {/*    <tbody>*/}
                    {/*        <tr>*/}
                    {/*            <th><span>Total Item</span></th>*/}
                    {/*            <td><span>{deliveryInfo.total_item}</span></td>*/}
                    {/*        </tr>*/}
                    {/*        <tr>*/}
                    {/*            <th><span>Total Quantity</span></th>*/}
                    {/*            <td><span>{deliveryInfo.quantity}</span></td>*/}
                    {/*        </tr>*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}

                    {/*Balance Info*/}
                    <table className="main balance" style={{ breakInside: "avoid" }}>
                        <tbody>
                            <tr>
                                <th><span>Total Item</span></th>
                                <td><span>{deliveryInfo.total_item}</span></td>
                            </tr>
                            <tr>
                                <th><span>Total Quantity</span></th>
                                <td><span>{deliveryInfo.total_quantity}</span></td>
                            </tr>
                            <tr>
                                <th><span>Delivery Cost</span></th>
                                <td><span>{deliveryInfo.delivery_cost}</span></td>
                            </tr>
                        </tbody>
                        {/*<tbody>*/}
                        {/*    <tr>*/}
                        {/*        <th><span>Sub Total</span></th>*/}
                        {/*        <td><span>{invoiceInfo.sub_total}</span></td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th><span>PPN {invoiceInfo.tax}%</span></th>*/}
                        {/*        <td><span>{invoiceInfo.tax_total}</span></td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th><span>Total Amount</span></th>*/}
                        {/*        <td><span>{invoiceInfo.total_harga}</span></td>*/}
                        {/*    </tr>*/}
                        {/*</tbody>*/}
                    </table>
                </article>


                <aside className="footer-print mb-5" style={{ breakInside: "avoid" }}>
                    <h1><span>Remark</span></h1>
                    <div className="text-note">
                        <p>{deliveryInfo.remark}</p>
                    </div>
                </aside>

                <aside className="footer-print" style={{ breakInside: "avoid" }}>
                    <h1><span>Payment Info</span></h1>
                    <div className="text-note">
                        <p style={{marginBottom: "0.5em"}}>
                            <span style={{fontWeight: "bold" }}>
                                Bank Name :
                            </span> <span className="me-5">BCA</span>
                        </p>
                        <p style={{marginBottom: "0.5em"}}>
                            <span style={{fontWeight: "bold" }}>
                                Rek Number :
                            </span> <span className="me-5">7615997676123456</span> <br/>
                        </p>
                        <p style={{marginBottom: "3em"}}>
                            <span style={{fontWeight: "bold" }}>
                                Rek Name :
                            </span> <span className="me-5">Nusantara Digital Solution</span>
                        </p>
                    </div>
                </aside>

                <CardGroup className="text-center font-footer-info " style={{ breakInside: "avoid" }}>
                    <Card>
                        <Card.Header>Acknow By</Card.Header>
                        <Card.Body style={{ minHeight: "100px" }}>
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ minHeight: "30px" }}>
                            {deliveryInfo.acknowledged_by}
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Header>Send By</Card.Header>
                        <Card.Body>
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ minHeight: "30px" }}>
                            {deliveryInfo.send_by}
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Header>Customer</Card.Header>
                        <Card.Body>
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ minHeight: "30px" }}>
                            {deliveryInfo.destination}
                        </Card.Footer>
                    </Card>
                </CardGroup>



            </div>
        </div>
    )
});

export default DeliveryPrint;