/* Import Component React */
import { useRef, useEffect, useMemo, useState } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, Input, message, Space, Popconfirm} from "antd";
import { MaterialReactTable } from "material-react-table";

/* Time management variable */
import moment from "moment/moment";

/* Return the module */
function JenisBarangViewCreate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State validation
    const [messageApi, contextHolder] = message.useMessage();
    //Prepare variable
    const [jenisBarang, setJenisBarang] = useState([]);
    const [idUpdate, setIdUpdate] = useState(null);
    const columns = useMemo(
        () => [
            {
                accessorKey: "nama_jenis_barang",
                header: "Jenis Barang",
            },{
                accessorFn: (row) => moment(row.created_at).format('lll'),
                header: "Created At",
            }
            // eslint-disable-next-line
        ], []
    );

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `Jenis barang ${value} is saved`,
        });
    }

    //Error message
    const errorStore = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    //Error message
    const errorUpdate = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    //Show message when cancel delete
    const cancelDeleteJenisBarang = (e) => {
        message.error('Void Deleted');
    };

    /* Handle connection, data and action */
    //Processing store document
    const storeJenisBarang = async (values) => {
        //Send data to backend
        await Api.post('/jenis-barang', {
            nama_jenis_barang: values.nama_jenis_barang,
        }).then(() => {
            success(values.nama_jenis_barang);
            //Fetch new data
            fetchJenisBarang().then(response => {
                setJenisBarang(response.data.data);
            });
            onReset();
            setIdUpdate(null);
        }).catch((error) => {
            errorStore(error.response.data.data);
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
        setIdUpdate(null);
    }

    /* View */
    //Connection to api to get data
    async function fetchJenisBarang() {
        //Await
        return await Api.get('/jenis-barang');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        fetchJenisBarang().then(response => {
            setJenisBarang(response.data.data);
        }).catch(() => {
            setJenisBarang([]);
        });
    }, []);

    //Confirm delete data
    const confirmDeleteJenisBarang = async (uuid, nama_jenis_barang) => {
        //Sending request delete
        await Api.delete(`/jenis-barang/${uuid}`);

        //Fetch new data
        fetchJenisBarang().then(response => {
            setJenisBarang(response.data.data);
        });
        message.success(`Jenis barang ${nama_jenis_barang} deleted`);
    };
    /* End View */

    /* Update */
    //Connection to api for find data
    async function fetchFindData(id){
        //Await
        return await Api.get(`/jenis-barang/${id}`);
        //...
    }

    //Fill data to form
    const fillForm = (id) => {
        fetchFindData(id).then(response => {
            //Get response data
            const doc = response.data.data

            //Assign doc to field
            formRef.current?.setFieldsValue({
                nama_jenis_barang: doc.nama_jenis_barang,
            });

            //Set id Update
            setIdUpdate(doc.uuid);
        });
    }

    //Processing update document
    const updateJenisBarang = async (values) => {
        //Send data to backend
        await Api.post(`/jenis-barang/${idUpdate}`, {
            nama_jenis_barang: values.nama_jenis_barang,
            _method: 'PUT',
        }).then(() => {
            onReset();
            success(values.nama_jenis_barang);
            //Fetch new data
            fetchJenisBarang().then(response => {
                setJenisBarang(response.data.data);
            });
            setIdUpdate(null);
        }).catch((error) => {
            errorUpdate(error.response.data.message);
        })
    }
    /* End Update */

    //Processing form
    const processForm = (values) => {
        idUpdate === null ? (storeJenisBarang(values)) : (updateJenisBarang(values));
    }

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            {contextHolder}
            <Col xs sm={8}>
                <Card className="card-body">
                    <MaterialReactTable
                        columns={columns}
                        data={jenisBarang}
                        enableGlobalFilter={true}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        initialState={{ density: 'compact' }}
                        enableColumnResizing={true}
                        columnResizeMode="onChange"
                        enableRowNumbers={true}
                        enableRowActions={true}
                        positionActionsColumn="last"
                        renderRowActions={({ row}) => (
                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this?"
                                onConfirm={() => confirmDeleteJenisBarang(row.original.uuid, row.original.nama_jenis_barang)}
                                onCancel={cancelDeleteJenisBarang}
                                okText="Yes"
                                cancelText="No"
                            > <Button size="small"> Delete </Button>
                            </Popconfirm>
                        )}
                        //Double click for edit
                        muiTableBodyCellProps={({ row }) => ({
                            onDoubleClick: (e) => {
                                fillForm(row.original.uuid);
                            },
                        })}
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: '#1976d2',
                            },
                        }}
                        muiTableContainerProps={{
                            sx: {
                                minHeight: '400px',
                                maxHeight: '600px'
                            }
                        }}
                    />
                </Card>
            </Col>
            <Col xs sm={4}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Entry Jenis Barang
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form layout="vertical" onFinish={processForm} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-4">
                                <Form.Item label="Jenis Barang" name="nama_jenis_barang" rules={[
                                    { required: true, message: "Please input jenis barang" },
                                    { min: 2, message: "Jenis barang name must have min 2 character" }
                                ]}>
                                    <Input showCount maxLength={100} />
                                </Form.Item>

                                <hr className="my-3" />
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <Button onClick={onReset} htmlType="button">
                                            Reset
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    );
}

export default JenisBarangViewCreate;