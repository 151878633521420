/* Import Style */
import {Form, Input, Select} from "antd";
import {Col, Row} from "react-bootstrap";
import {useState} from "react";

/* Return component */
function BarangForm() {
    //State document
    const [passwordDisabled, setPasswordDisabled] = useState(true);
    const [requiredPassword, setRequiredPassword] = useState({});
    const levels = [
        {
            'value': "Super Admin",
            'label': "Super Admin",
        },{
            'value': "Admin Purchasing",
            'label': "Admin Purchasing",
        }, {
            'value': "Admin Logistic",
            'label': "Admin Logistic",
        }
    ];
    const status = [
        {
            'value': "true",
            'label': "Active",
        },{
            'value': "false",
            'label': "Not Active",
        }
    ];

    const enablePassword = () => {
        setPasswordDisabled(false);
        setRequiredPassword({ required: true, message: "Please input password" })
    };


    return (
        <>
            <Row>
                <Col xs sm lg={8}>
                    <Form.Item label="Name" name="name" rules={[
                        { required: true, message: "Please input name" },
                        { min: 3, message: "Name must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Contact" name="contact" rules={[
                        { required: true, message: "Please input contact" },
                        { min: 11, message: "Contact must have min 11 character" }
                    ]}>
                        <Input showCount maxLength={13} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="User Name" name="username" rules={[
                        { required: true, message: "Please input user name" },
                        { min: 5, message: "User name must have min 5 character" }
                    ]} >
                        <Input disabled={true} showCount maxLength={15} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Level" name="level" rules={[ {required: true, message: "Please select a level"} ]}>
                        <Select placeholder="Select a level" allowClear options={levels} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Status" name="active_login" rules={[ {required: true, message: "Please select a status"} ]}>
                        <Select placeholder="Select a status" allowClear options={status} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={8}>
                    <Form.Item label="Password" name="password" rules={[
                        requiredPassword,
                        { min: 6, message: "Password must have min 6 character" }
                    ]} onDoubleClick={() => enablePassword()}>
                        <Input.Password disabled={passwordDisabled}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={8}>
                    {/*Space*/}
                </Col>

            </Row>
        </>
    )
}

export default BarangForm;