/* Import Component React */
import React, {useEffect, useMemo, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, Descriptions, message, Popconfirm, Skeleton} from 'antd';
import {MaterialReactTable} from "material-react-table";

/* Return component */
function StockDetail() {
    //Prepare variable
    const [infoStock, setInfoStock] = useState([]);
    const [detailStock, setDetailStock] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const navigate = useNavigate();
    let { id } = useParams();

    //Table
    const columns = useMemo(
        () => [
            {
                accessorKey: "doc_number",
                header: "Stock ID",
            },{
                accessorKey: "tanggal_masuk",
                header: "Tanggal Masuk",
            },{
                accessorKey: "manufaktur",
                header: "Manufaktur",
            },{
                accessorKey: "supplier",
                header: "Supplier",
            },{
                accessorKey: "harga_beli",
                header: "Harga Beli",
            },{
                accessorKey: "satuan",
                header: "Satuan",
            },{
                accessorKey: "stock_masuk",
                header: "Stock Masuk",
            },{
                accessorKey: "created_by",
                header: "Input By",
            }
            // eslint-disable-next-line
        ], []
    );

    /* Connection to api for get data */
    //Get data info
    async function fetchInfo(){
        //Await
        return await Api.get(`/stock-info/${id}`);
        //...
    }

    //Get data detail
    async function fetchDetail(uuid){
        //Await
        return await Api.get(`/stock-detail/${uuid}`);
        //....
    }

    //Hook data
    /*eslint-disable*/
    useEffect(() => {
        fetchInfo().then(response => {

            setInfoStock(response.data.data);

            fetchDetail(response.data.data.barang_id).then(response => {
                setDetailStock(response.data.data);
                setLoadingTable(false);
            }).catch(() => {
                setDetailStock([]);
            });
            setLoading(false);
        }).catch(() => {
            setInfoStock([]);
        });
    }, []);

    //Confirm delete data
    const confirmDelete = async (uuid, doc_number) => {
        //Sending request delete
        await Api.delete(`/stock/${uuid}`).then(response => {
            fetchDetail(response.data.data.barang_id).then(response => {
                setDetailStock(response.data.data);
                setLoadingTable(false);
            }).catch(() => {
                setDetailStock([]);
            });
            setLoading(false);
        }).catch(() => {
            message.error('Invalid error, please try again');
        });

        message.success(`${doc_number} is Deleted`);
    };

    //Show message when cancel delete
    const cancelDelete = (e) => {
        message.error('Delete Canceled');
    };

    return (
        <Row className="justify-content-center">
            <AuthVerify />
            <Skeleton active loading={loading}>
                <Col xs sm lg={9}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <MaterialReactTable
                                    state={{ isLoading: loadingTable }}
                                    columns={columns}
                                    data={detailStock}
                                    enablePinning
                                    enableGlobalFilter={true}
                                    enableDensityToggle={false}
                                    enableStickyHeader={true}
                                    initialState={{ density: 'compact', columnPinning: { left: ['doc_number', 'tanggal_masuk', 'stock_masuk'] }  }}
                                    enableColumnResizing={true}
                                    columnResizeMode="onChange"
                                    enableRowActions={true}
                                    positionActionsColumn="last"
                                    renderRowActions={({ row}) => (
                                        <Popconfirm
                                            title="Delete"
                                            description="Are you sure to delete this?"
                                            onConfirm={() => confirmDelete(row.original.uuid, row.original.doc_number)}
                                            onCancel={cancelDelete}
                                            okText="Yes"
                                            cancelText="No"
                                        > <Button size="small"> Delete </Button>
                                        </Popconfirm>
                                    )}
                                    Double click for edit
                                    muiTableBodyCellProps={({ row }) => ({
                                        onDoubleClick: (e) => {
                                            navigate(`/inventory/update/${row.original.uuid}`);
                                        },
                                    })}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            color: '#1976d2',
                                        },
                                    }}
                                    muiTableContainerProps={{
                                        sx: {
                                            minHeight: '350px',
                                            maxHeight: '550px'
                                        }
                                    }}
                                />
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs sm lg={3}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="header-form">No : {infoStock.nomor_barang}</ListGroup.Item>
                            <ListGroup.Item className="item-instruction">
                                <Descriptions>
                                    <Descriptions.Item label="No Part" className="m-0 p-1">{infoStock.nomor_part}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Harga Jual" className="m-0 p-1">{infoStock.harga_jual}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Nama Barang" className="m-0 p-1">{infoStock.nama_barang}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Jenis Barang" className="m-0 p-1">{infoStock.jenis_barang}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Kategori" className="m-0 p-1">{infoStock.kategori}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Sub Kategori" className="m-0 p-1">{infoStock.sub_kategori}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Stock In" className="m-0 p-1">{infoStock.stock_in}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Stock Out" className="m-0 p-1">{infoStock.stock_out}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Stock Ready" className="m-0 p-1">{infoStock.stock_ready}</Descriptions.Item>
                                </Descriptions>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Skeleton>
        </Row>
    )
}
export default StockDetail;