/* Import Component React */
import React, {useCallback, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../Api";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Skeleton, Space} from "antd";

/* Import form */
import {Info, StockForm} from "./StockForm";
import dayjs from "dayjs";

/* Return the module */
function SupplierUpdate() {

    /* Handle state and variable */
    //State document
    const [formRef] = Form.useForm();
    //State message
    const [messageApi, contextHolder] = message.useMessage();
    //Defined navigate
    const navigate = useNavigate();
    //Get params id
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(true);

    //State document form
    const [barang, setBarang] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [loadingBarang, setLoadingBarang] = useState(false);
    const [loadingSupplier, setLoadingSupplier] = useState(false);
    const [dataBarang, setDataBarang] = useState([]);

    /* Handle notification */
    //Error message
    const errorUpdate = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Connection to api for find data
    async function fetchData() {
        //Await
        return await Api.get(`/stock/${id}`);
        //...
    }

    //Hook data for form
    /* eslint-disable */
    useEffect(() => {
        //Fetch Select
        fetchSupplier().finally();
        fetchBarang().finally();

        fetchData().then(response => {
            //Get response data
            const doc = response.data.data

            //Assign doc to field
            formRef.setFieldsValue({
                barang_id: { value: doc.barang_rel.uuid, label : `${doc.barang_rel.part_number} - ${doc.barang_rel.nama_barang}` },
                satuan: doc.barang_rel.satuan_rel.uom,
                supplier_id: { value: doc.supplier_rel.uuid, label: doc.supplier_rel.nama_supplier },
                qty_in: doc.stock_masuk,
                qty_fill:  1,
                harga_beli: doc.harga_beli,
                tanggal_masuk: dayjs(doc.tanggal_masuk),
            });
            fetchDataBarang(doc.barang_rel.uuid).finally(); //Show info barang
            setLoading(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            cancel();
        });
    }, [id]);

    //Processing update document
    const update = async (values) => {
        //Prepare value options
        let barang_id, supplier_id;
        values.barang_id.value ? (barang_id = values.barang_id.value) : (barang_id = values.barang_id);
        values.supplier_id.value ? (supplier_id = values.supplier_id.value) : (supplier_id = values.supplier_id);

        //Send data to backend
        await Api.post(`/stock/${id}`, {
            barang_id: barang_id,
            supplier_id: supplier_id,
            qty_in: values.qty_in,
            qty_fill:  values.qty_fill,
            harga_beli: values.harga_beli,
            tanggal_masuk: dayjs(values.tanggal_masuk).format('YYYY/MM/DD'),
            created_by: localStorage.getItem('uuid_user'),
            _method: 'PUT',
        }).then(() => {
            message.success("Stock Updated");
            setTimeout(() => {
                cancel();
            }, 1000);
        }).catch((error) => {
            errorUpdate("Invalid server error, please try again");
        });
    }

    //cancel and back to main menu
    const cancel = () => {
        navigate(`/inventory/info/${id}`);
    }

    /* Form handle */
    //Fetch data supplier for select
    async function fetchSupplier() {
        //Await
        setLoadingSupplier(true);
        await Api.get('/supplier/select').then(response => {
            setSupplier(response.data.data);
            setLoadingSupplier(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            setSupplier([]);
        });
    }

    //Fetch data barang for select
    async function fetchBarang() {
        //Await
        setLoadingBarang(true);
        await Api.get('/data-barang/select').then(response => {
            setBarang(response.data.data);
            setLoadingBarang(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            setBarang([]);
        });
    }

    //Fetch Data Barang for info
    async function fetchDataBarang(value) {
        await Api.get(`/data-barang/index/${value}`).then(response => {
            setDataBarang(response.data.data);
            //Assign to field
            formRef.setFieldsValue({
                satuan: response.data.data.satuan,
            });
            setLoadingInfo(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            setDataBarang([]);
        });
    }

    //Handle select supplier
    const listSupplier = useCallback( () => {
        fetchSupplier().finally();
        /* eslint-disable */
    }, []);

    //Handle select barang
    const listBarang = useCallback(async () => {
        fetchBarang().finally();
        /* eslint-disable */
    }, [barang]);


    //Handel show info data barang
    const dataBarangDetail = useCallback(async (value) => {
        fetchDataBarang(value).finally()
    }, [barang]);

    //Reset form
    const onReset = () => {
        formRef.resetFields();
    }

    return (
        <Row className="justify-content-md-center">
            {contextHolder}
            <Col xs sm={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Update Data Stock
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card.Body>
                                <Form layout="vertical" onFinish={update} form={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-4">
                                    <Skeleton active loading={loading}>
                                        <StockForm
                                            supplier={listSupplier} loadingSupplier={loadingSupplier} optionsSupplier={supplier}
                                            barang={listBarang} satuan={dataBarangDetail} loadingBarang={loadingBarang} optionsBarang={barang}
                                        />
                                    </Skeleton>

                                    <hr className="my-3" />
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                            <Button onClick={cancel} htmlType="button">
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card.Body>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm={4}>
                <Card>
                    <Skeleton active loading={loadingInfo}>
                        <Info dataBarang={dataBarang}/>
                    </Skeleton>
                </Card>
            </Col>
        </Row>
    );
}

export default SupplierUpdate;