/* Import Component React */
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import {Popconfirm, message, Button} from "antd";
import { MaterialReactTable } from "material-react-table";

/* Time management variable */
import moment from "moment/moment";

/* Return the module */
function ManufactureView() {

    /* Handle state and variable */
    const [manufactures, setManufactures] = useState([]);
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "nama_manufaktur",
                header: "Manufacture",
            },{
                accessorKey: "brand",
                header: "Brand",
            },{
                accessorFn: (row) => moment(row.created_at).format('lll'),
                header: "Created At",
            }
            // eslint-disable-next-line
        ], []
    );

    /* Handle notification */
    //Show message when cancel delete
    const cancelDeleteManufacture = (e) => {
        message.error('Void Deleted');
    };

    /* Handle connection, data and action */
    //Connection to api to get data
    async function fetchManufacture() {
        //Await
        return await Api.get('/manufacture');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        fetchManufacture().then(response => {
            setManufactures(response.data.data);
        }).catch(() => {
            setManufactures([]);
        });
    }, []);

    //Navigate to form update
    const navigateManufacture = (uuid) => {
        navigate(`/inventory/manufacture/update/${uuid}`);
    }

    //Confirm delete data stuf
    const confirmDeleteManufacture = async (uuid, nama_manufaktur) => {
        //Sending request delete
        await Api.delete(`/manufacture/${uuid}`);

        //Fetch new data department
        fetchManufacture().then(response => {
            setManufactures(response.data.data);
        });
        message.success(`Manufacture ${nama_manufaktur} move to trash`);
    };


    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                columns={columns}
                data={manufactures}
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact' }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                renderRowActions={({ row}) => (
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteManufacture(row.original.uuid, row.original.nama_manufaktur)}
                        onCancel={cancelDeleteManufacture}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigateManufacture(row.original.uuid);
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
            />
        </Card>
    );
}

export default ManufactureView;