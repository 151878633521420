/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import KategoriViewCreate from "./KategoriViewCreate";


/* Return the module*/
function KategoriNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Kategori</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/kategori" eventKey="/inventory/kategori" active={location.pathname === "/inventory/kategori"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<KategoriViewCreate/>} />
            </Routes>
        </>
    );
}

export default KategoriNav;