/* Import component */
import React, {useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, message} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/* Import child component */
import DeliveryForm from "./DeliveryForm";

/* Main component */
function DeliveryCreate(props) {
    const userInfo = props.userInfo;
    const [formDelivery] = Form.useForm(); //For reference form delivery
    const [loadingButton, setLoadingButton] = useState(false);


    /*
    * Handle store delivery order.
    */
    const storeDelivery = async (values) => {
        setLoadingButton(true);
        await Api.post('/do', {
            company_id: userInfo.companyId,
            delivery_date: dayjs(values.delivery_date).format('YYYY-MM-DD'),
            invoice_id: values.invoice_id,
            origin_id: userInfo.companyId,
            destination_id: values.customer_id,
            delivery_via: values.delivery_via,
            delivery_cost: values.delivery_cost,
            remark: values.remark,
            created_by: userInfo.userId,
            send_by: values.send_by,
            acknowledged_by: values.acknowledged_by,
        }).then(response => {
            console.log(response);
            message.success("Delivery order created");
            props.fetchDelivery();
            props.closeModal();
            formDelivery.resetFields();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingButton(false);
    }


    return (
        <Form layout="vertical" onFinish={storeDelivery} form={formDelivery} initialValues={{ delivery_cost: 0 }} requiredMark={false} className="mt-3">
            <DeliveryForm
                btnTitle={"Submit"}
                loadingBtn={loadingButton}
            />
        </Form>
    )


}

export default DeliveryCreate;