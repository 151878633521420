/* Import component */
import React, {useEffect, useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, message} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/* Import child component */
import SaleForm from "./SaleForm";

/* Return component */
function SaleCreate(props) {
    /* Handle state */
    const [formRefCustomer] = Form.useForm(); //For reference form customer
    const [loadingButton, setLoadingButton] = useState(false);

    /* Set Default Value */
    useEffect(() => {
        formRefCustomer.setFieldsValue({
            margin: 0,
            tax: 0
        });
        /*eslint-disable*/
    }, []);



    /*
    * Processing store, fetch data for customer info, and set default value, form action and button action by existing data.
    */
    const storeInvoice = async (values) => {
        setLoadingButton(true);
        //Send data to backend
        await Api.post('/index-sale', {
            company_id: props.companyId,
            customer_id: values.customer_id,
            tanggal_penjualan: dayjs(values.tanggal_penjualan[0]).format('YYYY/MM/DD'),
            due_date: dayjs(values.tanggal_penjualan[1]).format('YYYY/MM/DD'),
            selling_type: values.selling_type,
            margin: values.margin,
            tax: values.tax,
            created_by: props.userId,
        }).then(() => {
            message.success("Invoice Created");
            props.fetchSale();
            props.closeModal();
            formRefCustomer.resetFields();
            formRefCustomer.setFieldsValue({
                margin: 0,
                tax: 0,
            });
        }).catch((error) => {
            console.log(error);
            message.error("Invalid server error, please try again");
        });
        setLoadingButton(false);
    }

    return (
        <Form layout="vertical" onFinish={storeInvoice} form={formRefCustomer} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
            <SaleForm
                btnTitle={"Submit"}
                loadingBtn={loadingButton}
                loading={false}
            />
        </Form>
    )
}

export default SaleCreate;