/* Import component */
import React, {useEffect, useState, useCallback, useRef} from "react";
import Api from "../Api";

/* Import Style */
import {DatePicker, Form, InputNumber, Select, Button, message} from "antd";

/* Import Time Component */
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';
import TextArea from "antd/es/input/TextArea";

/* Main component */
function DeliveryForm(props) {
    const focusRef = useRef(null);
    const [listCustomer, setListCustomer] = useState([]);
    const [listInvoice, setListInvoice] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [loadingListCustomer, setLoadingListCustomer] = useState(false);
    const [loadingListInvoice, setLoadingListInvoice] = useState(false);
    const [loadingListUser, setLoadingListUser] = useState(false);
    const [disableFieldInvoice, setDisableFieldInvoice] = useState(true);
    const listDelivery = [
        {
            'value': "Hand Carry",
            'label': "Hand Carry",
        },{
            'value': "Courier",
            'label': "Courier",
        },
    ];


    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);


    /*
    * Handle list customer, Trigger form select, Fetch data customer.
    */
    //Handle select customer
    const handleListCustomer = useCallback( () => {
        fetchListCustomer().finally();
        /* eslint-disable */
    }, [listCustomer]);

    //Fetch list customer
    async function fetchListCustomer() {
        //Await
        setLoadingListCustomer(true);
        await Api.get('/customer/select').then(response => {
            setListCustomer(response.data.data);
            console.log("list customer running");
        }).catch((error) => {
            console.log(error);
            message.error("Invalid server error, please try again");
            setListCustomer([]);
            setDisableFieldInvoice(true);
        });
        setLoadingListCustomer(false);
    }


    /*
    * Fetch list invoice by trigger select customer.
    */
    const handleSelect = useCallback ((value) => {
        fetchListInvoice(value).finally()
        setDisableFieldInvoice(false);
    }, [listCustomer]);


    /*
    * Handle list invoice, Trigger form select, Fetch data invoice.
    */
    // const handleListInvoice = useCallback(() => {
    //     fetchListInvoice().finally();
    //     /* eslint-disable */
    // }, [listInvoice]);

    //Fetch list invoice
    async function fetchListInvoice(value) {
        //Await
        setLoadingListInvoice(true);
        await Api.get(`/index-sale-select/${value}`).then(response => {
            setListInvoice(response.data.data);
        }).catch((error) => {
            console.log(error);
            message.error("Invalid server error, please try again");
            setListInvoice([]);
        });
        setLoadingListInvoice(false);
    }


    /*
    * Handle list user, Trigger form select, Fetch data user.
    */
    const handleListUser = useCallback(async () => {
        fetchListUser().finally();
        /* eslint-disable */
    }, [listCustomer]);

    //Fetch list user
    async function fetchListUser() {
        //Await
        setLoadingListUser(true);
        await Api.get(`/user/select`).then(response => {
            setListUser(response.data.data);
        }).catch((error) => {
            console.log(error);
            message.error("Invalid server error, please try again");
            setListUser([]);
        });
        setLoadingListUser(false);
    }

    return (
        <>
            <Form.Item label="Reference Customer" name="customer_id" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListCustomer} onFocus={handleListUser} loading={loadingListCustomer} options={listCustomer} ref={focusRef} onSelect={handleSelect}/>
            </Form.Item>

            <Form.Item label="Reference Invoice" name="invoice_id" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } /*onClick={handleListInvoice} onFocus={handleListInvoice}*/ loading={loadingListInvoice} options={listInvoice} disabled={disableFieldInvoice}/>
            </Form.Item>

            <Form.Item label="Delivery Date" name="delivery_date" rules={[
                { required: true, message: "Please input delivery date" }
            ]}>
                <DatePicker locale={locale} style={{ width: "100%"}}/>
            </Form.Item>

            <Form.Item label="Delivery Via" name="delivery_via" rules={[ {required: true, message: "Please select a selling type"} ]}>
                <Select placeholder="Select a selling type" allowClear options={listDelivery} />
            </Form.Item>

            <Form.Item label="Delivery Cost" name="delivery_cost" rules={[
                { required: true, message: "Please input delivery cost" }
            ]}>
                <InputNumber
                    style={{ width: "100%" }}
                    prefix="Rp."
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
            </Form.Item>

            <Form.Item label="Remark" name="remark" rules={[ {required: true, message: "Please input remark"} ]}>
                <TextArea showCount maxLength={300} />
            </Form.Item>

            <Form.Item label="Send By" name="send_by" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a user" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListUser} onFocus={handleListUser} loading={loadingListUser} options={listUser} />
            </Form.Item>

            <Form.Item label="Acknowledged By" name="acknowledged_by" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a user" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } /*onClick={handleListUser} loading={loadingListUser}*/ options={listUser} />
            </Form.Item>

            <hr/>

            <Button type="primary" htmlType="submit" style={{ width: "100%"}} loading={props.loadingBtn}>
                {props.btnTitle}
            </Button>
        </>
    )

}

export default DeliveryForm;