/* Import Component React */
import React, { useEffect, useMemo, useState } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import { Card } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {DatePicker, Space} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

/* Import Component Export */
import ReportStockToExcel from "./ReportStockToExcel";

/* Main component */
function ReportStockDetail() {
    //Prepare variable
    const companyName = localStorage.getItem('company_name');
    const [stock, setStock] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataExport, setDataExport] = useState([]);
    const fileName = `${companyName} - Stock Detail`;
    const { RangePicker } = DatePicker;
    const columns = useMemo(
        () => [
            {
                accessorKey: "doc_number",
                header: "Stock ID",
            },{
                accessorKey: "nama_barang",
                header: "Nama Barang",
            },{
                accessorKey: "nomor_part",
                header: "Part Number",
            },{
                accessorKey: "tanggal_masuk",
                header: "Tanggal Masuk",
            },{
                accessorKey: "manufaktur",
                header: "Manufaktur",
            },{
                accessorKey: "supplier",
                header: "Supplier",
            },{
                accessorKey: "satuan",
                header: "Satuan",
            },{
                accessorKey: "stock_masuk",
                header: "Stok Masuk",
            },{
                accessorKey: "kategori",
                header: "Kategori",
            },{
                accessorKey: "sub_kategori",
                header: "Sub Kategori",
            },{
                accessorKey: "created_By",
                header: "Created By",
            },
            // eslint-disable-next-line
        ], []
    );

    //Hook data in first rendering
    useEffect(() => {
        fetchDataPeriod().finally();
    }, []);


    /*
    * Handle report period.
    */
    const handleReportPeriod = (values) => {
        fetchDataPeriod(values).finally();
    }


    //Connection to api to get data
    async function fetchDataPeriod(values) {
        //Prepare variable
        const startDate = values ? dayjs(values[0]).format('YYYY-MM-DD') : '0';
        const endDate = values ? dayjs(values[1]).format('YYYY-MM-DD') : '0';

        setLoading(true);
        await Api.get(`/report-stock-period/${startDate}/${endDate}`).then(response => {
            setStock(response.data.data);
            // Reshaping the array
            const headings = response.data.data.map( (item, index) => ({
                "No": index+1,
                "Stock Id": item.doc_number,
                "Nama Barang": item.nama_barang,
                "Part Number": item.nomor_part,
                "Tanggal Masuk": item.tanggal_masuk,
                "Manufaktur": item.manufaktur,
                "Supplier": item.supplier,
                "Satuan": item.satuan,
                "Stock Masuk": item.stock_masuk,
                "Kategori": item.kategori,
                "Sub Kategori": item.sub_kategori,
                "Created By": item.created_by,
            }));
            setDataExport(headings);
        }).catch(error => {
            console.log(error);
            setStock([]);
        });
        setLoading(false);
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={stock}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'doc_number', 'nama_barang'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <Space sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                        <RangePicker placeholder={ ['Start date', 'End date'] } locale={locale} onChange={handleReportPeriod}/>
                        <ReportStockToExcel apiData={dataExport} fileName={fileName}/>
                    </Space>
                )}
            />
        </Card>
    );
}

export default ReportStockDetail;