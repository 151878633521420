import {Col, Container, Ratio, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Api from "../Api";

function CompanyLogo() {
    const company_logo = localStorage.getItem('company_logo');

    return (
        <>
            {
                company_logo === "undefined" ? (
                    <img
                        alt=""
                        src="/logoAJG.png"
                        width="50"
                        height="45"
                        className="d-inline-block align-top"
                    />
                ) : (
                    <Ratio aspectRatio="1x1">
                        <img
                            alt=""
                            src={company_logo}
                            className="d-inline-block align-top"
                        />
                    </Ratio>
                )
            }
        </>
    )
}

function CompanyName() {
    const company_name = localStorage.getItem('company_name');

    return (
        <>
            {
                company_name === "undefined" ? (
                    <span className="h3 text-dark">Enterprise Resource Planning</span>
                ) : (
                    <span className="h3 text-dark">{company_name}</span>
                )
            }
        </>
    )
}

function CompanyInfo() {
    const [companyInfo, setCompanyInfo] = useState([]);

    useEffect(() => {
        fetchCompany().finally();
    }, []);

    const fetchCompany = async () => {
        //Get data company
        await Api.get(`/company/${localStorage.getItem('company_id')}`).then(response => {
            setCompanyInfo(response.data.data);
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <Container style={{ maxHeight: "80px"}}>
            <Row >
                <Col sm={2}>
                    <CompanyLogo />
                </Col>
                <Col sm={10}>
                    <CompanyName /> <br/>
                    <p className="font-weight-lighter mb-0 pb-0 font-description-company">
                        {companyInfo.company_address} <br/>
                        Telp: {companyInfo.company_contact} Email : {companyInfo.company_email}
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default CompanyInfo;