/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import DeliveryView from "./DeliveryView";
import DeliveryHistoryView from "./DeliveryHistoryView";
import DeliveryHistoryDetail from "./DeliveryHistoryDetail";
import DeliveryHistoryTrash from "./DeliveryHistoryTrash";

/* Return the module*/
function DeliveryNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Delivery Order</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link} to="/inventory/delivery" eventKey="/inventory/delivery" active={location.pathname === "/inventory/delivery"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Delivery
                        </Button>
                        <Button as={Link}  to="/inventory/delivery/history" eventKey="/inventory/delivery/history" active={location.pathname === "/inventory/delivery/history"} variant="outline-secondary" size="sm" className="btn-navigation">
                            History
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button as={Link} to="/inventory/delivery/trash" eventKey="/inventory/delivery/trash" active={location.pathname === "/inventory/delivery/trash"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Trash
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<DeliveryView/>} />
                <Route path="history" element={<DeliveryHistoryView />} />
                <Route path="detail/:uuid" element={<DeliveryHistoryDetail />} />
                <Route path="trash" element={<DeliveryHistoryTrash />} />
            </Routes>
        </>
    );
}

export default DeliveryNav;