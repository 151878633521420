/* Import component React */
import React from "react";
import {useLocation, Link} from "react-router-dom";

/* Import style */
import {Nav, NavDropdown, Offcanvas} from "react-bootstrap";
import {Archive, Bookmark, Clipboard, File, HardDrive, LogOut, MapPin, Monitor, Trello, Users} from "react-feather";

function UserMenu(props) {
    const level = localStorage.getItem('level');
    //Location path
    const location = useLocation();

    const handleMenu = () => {
        switch(level) {
            case 'Super Admin':
                return [
                    <DataManagement/>,
                    <LogisticManagement/>,
                    <PurchaseManagement/>,
                    <AdmManagement/>,
                    <ReportManagement/>
                ];
            case 'Admin Purchasing':
                return [
                    <PurchaseManagement/>,
                    <ReportManagement/>
                ];
            case 'Admin Logistic':
                return [
                    <LogisticManagement/>,
                    <ReportManagement/>
                ];
            default:
                return [
                    <DataManagement/>,
                ];
        }
    }

    return  (
        <Offcanvas.Body className="d-md-flex flex-column p-0 pt-lg-2 pt-md-2">
            <Nav activeKey={location.pathname} className="flex-column">
                {handleMenu()}
            </Nav>

            <hr className="my-3" />
            <Nav>
                <Nav.Link onClick={props.logout}>
                    <LogOut className="feather"/>
                    Sign Out
                </Nav.Link>
            </Nav>
        </Offcanvas.Body>
    );
}

export default UserMenu;

function DataManagement() {
    const navData = (<><HardDrive className="feather"/> Data </>);

    return (
        <>
            {/*<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary">*/}
            {/*    <span>Data Management</span>*/}
            {/*</h6>*/}
            <NavDropdown title={navData} id="collasible-nav-dropdown" drop="down-centered">
                <NavDropdown.Item as={Link} to="/inventory/jenis-barang" eventKey="/inventory/jenis-barang">Jenis Barang</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/inventory/kategori" eventKey="/inventory/kategori">Kategori</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/inventory/sub-kategori" eventKey="/inventory/sub-kategori">Sub Kategori</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/inventory/manufacture" eventKey="/inventory/manufacture">Manufacture</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/inventory/uom" eventKey="/inventory/uom">UOM</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/inventory/supplier" eventKey="/inventory/supplier">Supplier</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/inventory/customer" eventKey="/inventory/customer">Customer</NavDropdown.Item>
            </NavDropdown>
        </>
    )
}

function PurchaseManagement() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary">
                <span>Purchase</span>
            </h6>
            <Nav.Link as={Link} to="/inventory/quotation" eventKey="/inventory/quotation"> <Clipboard className="feather"/> Quotation</Nav.Link>
            <Nav.Link as={Link} to="/inventory/sale" eventKey="/inventory/sale"> <Monitor className="feather"/> Sale</Nav.Link>
        </>
    )
}

function LogisticManagement() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary">
                <span>Logistic</span>
            </h6>
            <Nav.Link as={Link} to="/inventory/data-barang" eventKey="/inventory/data-barang"> <Trello className="feather"/> Data Barang</Nav.Link>
            <Nav.Link as={Link} to="/inventory/stock" eventKey="/inventory/stock"> <Archive className="feather"/> Stock</Nav.Link>
            <Nav.Link as={Link} to="/inventory/delivery" eventKey="/inventory/delivery"> <MapPin className="feather"/> Delivery Order</Nav.Link>
        </>
    )
}

function AdmManagement() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary">
                <span>Administrator</span>
            </h6>
            <Nav.Link as={Link} to="/inventory/company" eventKey="/inventory/company"> <Bookmark className="feather"/> Company</Nav.Link>
            <Nav.Link as={Link} to="/inventory/user" eventKey="/inventory/user"> <Users className="feather"/> Users</Nav.Link>
        </>
    )
}

function ReportManagement() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary">
                <span>Reports</span>
            </h6>
            <Nav.Link as={Link} to="/inventory/report-stock" eventKey="/inventory/report-stock"> <File className="feather"/>Report Stock</Nav.Link>
            <Nav.Link as={Link} to="/inventory/report-sale" eventKey="/inventory/report-sale"> <File className="feather"/>Report Sale</Nav.Link>
        </>
    )
}