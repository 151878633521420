/* Import Component React */
import React, { useEffect, useMemo, useState } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import { Card } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";

/* Import Component Export */
import ReportStockToExcel from "./ReportStockToExcel";

/* Main Component */
function ReportStockSummary() {
    //Prepare variable
    const companyName = localStorage.getItem('company_name');
    const [stock, setStock] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataExport, setDataExport] = useState([]);
    const fileName = `${companyName} - Stock Summary`;
    const columns = useMemo(
        () => [
            {
                accessorKey: "nomor_barang",
                header: "Kode Barang",
            },{
                accessorKey: "nama_barang",
                header: "Nama Barang",
            },{
                accessorKey: "nomor_part",
                header: "Part Number",
            },{
                accessorKey: "harga",
                header: "Harga",
            },{
                accessorKey: "stock_in",
                header: "Stock In",
            },{
                accessorKey: "stock_out",
                header: "Stock Out",
            },{
                accessorKey: "stock",
                header: "Stock Ready",
            },{
                accessorKey: "manufaktur",
                header: "Manufaktur",
            },{
                accessorKey: "kategori",
                header: "Kategori",
            },{
                accessorKey: "sub_kategori",
                header: "Sub Kategori",
            },
            // eslint-disable-next-line
        ], []
    );

    //Hook data in first rendering
    useEffect(() => {
        fetchData().finally();
    }, []);

    //Connection to api to get data
    async function fetchData() {
        setLoading(true);
        await Api.get('/report-stock').then(response => {
            setStock(response.data.data);
            // Reshaping the array
            const headings = response.data.data.map( (item, index) => ({
                "No": index+1,
                "Kode Barang": item.nomor_barang,
                "Nama Barang": item.nama_barang,
                "Part Number": item.nomor_part,
                "Harga": item.harga,
                "Stock In": item.stock_in,
                "Stock Out": item.stock_out,
                "Stock Ready": item.stock,
                "Manufaktur": item.manufaktur,
                "Kategori": item.kategori,
                "Sub Kategori": item.sub_kategori,
            }));
            setDataExport(headings);
        }).catch(() => {
            setStock([]);
        });
        setLoading(false);
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={stock}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'nomor_barang', 'nama_barang'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <ReportStockToExcel apiData={dataExport} fileName={fileName}/>
                )}
            />
        </Card>
    );
}

export default ReportStockSummary;