/* Import component react */
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import Api from '../Api';
import AuthVerify from "../AuthVerify";

/* Import style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, DatePicker, Descriptions, Form, Input, InputNumber, message, Modal, Skeleton} from "antd";

/* Import Component Child */
import {SaleTableItemDetail} from "./SaleTableItem";
import SaleInvoicePrint from "./SaleInvoicePrint";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

/* Main component */
function SaleHistoryDetail() {
    /* Handle state and variable */
    /*eslint-disable*/
    const [formCustomerInfo] = Form.useForm();
    const [formPayment] = Form.useForm();
    const [saleInfo, setSaleInfo] = useState([]);
    const [saleItemInfo, setSaleItemInfo] = useState([]);
    const [saleItem, setSaleItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [modalUpdatePayment, setModalUpdatePayment] = useState(false);
    let {uuid} = useParams();

    /* Handle Print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    /*
    * Hook data in first rendering.
    */
    useEffect(() => {
        fetchSale().finally();
        /*eslint-disable*/
    },[]);


    /* Formatting Number */
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-ID').format(number);
    }


    /*
    * Fetch function for get data from backend.
    */
    const fetchSale = async () => {
        setLoading(true);
        //Await
        await Api.get(`/index-sale-detail/${uuid}`).then(response => {
            const data = response.data.data;
            setSaleInfo(data);
            setSaleItemInfo(data);
            setSaleItem(data.item);
            formCustomerInfo.setFieldsValue({
                customer_info: data.customer,
                tanggal_penjualan_info: data.tanggal_penjualan,
                created_by_info: data.created_by
            });
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
        setLoading(false);
    }


    /*
    * Handle update payment.
    */
    const handleUpdatePayment = async (values) => {
        setLoadingBtn(true);
        await Api.post(`/index-sale-payment/${uuid}`, {
            tanggal_penjualan: dayjs(values.tanggal_penjualan[0]).format('YYYY/MM/DD'),
            due_date: dayjs(values.tanggal_penjualan[1]).format('YYYY/MM/DD'),
            payment_received: values.payment_received,
            _method: 'PUT',
        }).then(response => {
            console.log(response);
            fetchSale();
            message.success("Payment updated");
            setModalUpdatePayment(false);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingBtn(false);
    }

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            <Skeleton active loading={loading}>
                <Col xs sm lg={9}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="p-1 pt-0 pb-0 me-2 ms-2">
                                <Form layout="vertical" form={formCustomerInfo} requiredMark={false} className="mt-3">
                                    <Row>
                                        <Col xs sm lg={5}>
                                            <Form.Item label="Customer" name="customer_info" onClick={() => setOpenModalCustomer(true)}  rules={[
                                                { required: true, message: "Please input customer" },
                                            ]}>
                                                <Input readOnly={true} className="text-dark" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs sm lg={3}>
                                            <Form.Item label="Transaction Date" name="tanggal_penjualan_info" rules={[
                                                { required: true, message: "Please input delivery date" }
                                            ]}>
                                                <Input readOnly={true} className="text-dark"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs sm lg={4}>
                                            <Form.Item label="Created By" name="created_by_info"  rules={[
                                                { required: true, message: "Please input user" },
                                            ]}>
                                                <Input readOnly={true} className="text-dark" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </ListGroup.Item>

                            <ListGroup.Item className="m-0 p-1">
                                <SaleTableItemDetail
                                    loading={loading}
                                    data={saleItem}
                                />
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs sm lg={3}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="header-form">No : {saleInfo.nomor_invoice}</ListGroup.Item>
                            <ListGroup.Item className="item-instruction">
                                <Descriptions>
                                    <Descriptions.Item label="Do Number" className="m-0 p-1">{saleInfo.delivery_order}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Status" className="m-0 p-1">{saleInfo.payment_status}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Type" className="m-0 p-1">{saleInfo.selling_type}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Total Item" className="m-0 p-1">{saleItemInfo.total_item}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Quantity" className="m-0 p-1">{saleItemInfo.quantity}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Sub Total" className="m-0 p-1">{saleItemInfo.sub_total}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Margin%" className="m-0 p-1">{saleInfo.margin}%</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="PPN%" className="m-0 p-1">{saleInfo.tax}%</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="PPN" className="m-0 p-1">{saleItemInfo.tax_total}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Total" className="m-0 p-1">{saleItemInfo.total_price}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Transaksi" className="m-0 p-1">{saleInfo.tanggal_penjualan}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Jatuh Tempo" className="m-0 p-1">{saleInfo.due_date}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Payment" className="m-0 p-1">Rp. {formatNumber(saleInfo.payment_received)}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Tagihan" className="m-0 p-1">Rp. {formatNumber(saleInfo.bill)}</Descriptions.Item>
                                </Descriptions>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button htmlType="button" onClick={() => setModalUpdatePayment(true)} style={{ width: "100%" }}>
                                    Update Payment
                                </Button>

                                <Button type="primary" onClick={handlePrint} style={{ width: "100%", marginTop: "10px" }}>
                                    Print Invoice
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Skeleton>

            {/*Modal Update Payment*/}
            <Modal
                title="Update Payment"
                open={modalUpdatePayment}
                onCancel={() => setModalUpdatePayment(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable footer for replace submit button*/
                ]}
            >
                <Form layout="vertical" onFinish={handleUpdatePayment} form={formPayment}
                      initialValues={{
                          payment_received: 0,
                          tanggal_penjualan: [dayjs(saleInfo.tanggal_penjualan), dayjs(saleInfo.due_date)]
                      }}
                      requiredMark={false} className="mt-3">

                    <UpdatePaymentForm
                        invoiceInfo={saleInfo}
                        loading={loadingBtn}
                    />

                </Form>

            </Modal>

            {/* Component to be printed */}
            <div className="hide-print-content">
                <SaleInvoicePrint
                    ref={componentRef}
                    invoice={{saleInfo, saleItemInfo, saleItem}}
                    nominalPayment={saleInfo.payment_received}
                />
            </div>
        </Row>
    )

}

export default SaleHistoryDetail;

function UpdatePaymentForm(props) {
    const { RangePicker } = DatePicker;
    const saleInfo = props.invoiceInfo;
    const loading  = props.loading;

    /* Formatting Number */
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-ID').format(number);
    }


    return (
        <ListGroup variant="flush">
            <ListGroup.Item className="item-instruction">
                <Form.Item label="Tanggal" name="tanggal_penjualan" className="mb-4" rules={[
                    { required: true, message: "Please input tanggal penjualan" }
                ]}>
                    <RangePicker placeholder={ ['Penjualan', 'Jatuh Tempo'] } locale={locale} style={{ width: "100%"}}/>
                </Form.Item>

                <Descriptions className="border-bottom">
                    <Descriptions.Item label="Payment Received" className="m-0 p-1 w-50">{formatNumber(saleInfo.payment_received)}</Descriptions.Item>
                </Descriptions>

                <Descriptions className="border-bottom">
                    <Descriptions.Item label="Tagihan" className="m-0 p-1 w-50">{formatNumber(saleInfo.bill)}</Descriptions.Item>
                </Descriptions>

                <Form.Item label="Payment Received" name="payment_received" className="mt-4" rules={[
                    { required: true, message: "Please input payment received" }
                ]}>
                    <InputNumber
                        style={{ width: "100%" }}
                        prefix="Rp."
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>
            </ListGroup.Item>

            <ListGroup.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading}>
                    Submit
                </Button>
            </ListGroup.Item>
        </ListGroup>
    )
}