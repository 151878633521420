/* Import Component React */
import React, {useEffect, useRef, useState} from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";
import {useNavigate, useParams} from "react-router-dom";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space, Upload} from "antd";

/* Import form */
import CompanyForm from "./CompanyForm";
import {UploadOutlined} from "@ant-design/icons";

/* Return the module */
function CompanyUpdate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State validation
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    let { id } = useParams();

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `Company ${value} is saved`,
        }).then(() => {
            cancel();
        });
    }

    //Error message
    const errorStore = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Connection to api for find data
    async function fetchData() {
        //Await
        return await Api.get(`/company/${id}`);
        //...
    }

    //Hook data for form
    useEffect(() => {
        fetchData().then(response => {
            //Get response data
            const doc = response.data.data

            //Asign doc to field
            formRef.current?.setFieldsValue({
                company_name: doc.company_name,
                company_contact: doc.company_contact,
                company_email: doc.company_email,
                company_address: doc.company_address,
            });
            /*eslint-disable*/
        }).catch(() => {
            cancel();
        })
    }, [id]);
    const updateCompany = async (values) => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('company_logo', file);
        });

        //append data
        formData.append('company_name', values.company_name);
        formData.append('company_contact', values.company_contact);
        formData.append('company_email', values.company_email);
        formData.append('company_address', values.company_address);
        formData.append('_method', 'PUT');


        //Send data to backend
        setUploading(true);
        await Api.post(`/company/${id}`, formData).then(() => {
            setFileList([]);
            success(values.company_name);
        }).catch((error) => {
            errorStore(error.response.data.data);
        }).finally(() => {
            setUploading(false);
            cancel();
        });
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    //cancel form
    const cancel = () => {
        navigate('/inventory/company');
    }

    return (
        <Form layout="vertical" onFinish={updateCompany} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
        <Row className="justify-content-md-center">
            <AuthVerify />
            {contextHolder}
            <Col xs sm lg={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Update Company
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <CompanyForm/>

                            <hr className="my-3" />
                            <Form.Item>
                                <Space>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        // disabled={fileList.length === 0}
                                        loading={uploading}
                                    >
                                        {uploading ? 'Uploading' : 'Save'}
                                    </Button>
                                    <Button onClick={cancel} htmlType="button">
                                        Cancel
                                    </Button>
                                </Space>
                            </Form.Item>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm lg={4}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">Upload Logo</ListGroup.Item>
                        <ListGroup.Item className="item-instruction">
                            <Upload {...props} >
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
        </Form>
    );
}

export default CompanyUpdate;