/* Import Component */
import {useEffect, useRef} from "react";

/* Import Style */
import {Form, Input} from "antd";
import {Col, Row} from "react-bootstrap";
import TextArea from "antd/es/input/TextArea";


/* Return component */
function CompanyForm() {
    //Variable
    const  inputRef = useRef(true);

    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    return (
        <>
            <Row>
                <Col xs sm lg={8}>
                    <Form.Item label="Company Name" name="company_name" rules={[
                        { required: true, message: "Please input company name" },
                        { min: 5, message: "Company name must have min 5 character" }
                    ]}>
                        <Input showCount maxLength={100} ref={inputRef} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Telephone" name="company_contact" rules={[
                        { required: true, message: "Please input company telephone" },
                        { min: 8, message: "Company telephone must have min 8 character" }
                    ]}>
                        <Input showCount maxLength={13} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={8}>
                    <Form.Item label="Email" name="company_email" rules={[
                        { required: true, message: "Please input company email" },
                        { min: 10, message: "Company email must have min 10 character" }
                    ]} >
                        <Input showCount maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    {/*Space*/}
                </Col>
                <Col xs sm lg={12}>
                    <Form.Item label="Address" name="company_address" rules={[
                        { required: true, message: "Please input company address" },
                        { min: 10, message: "Company address must have min 10 character" }
                    ]} >
                        <TextArea showCount maxLength={300} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default CompanyForm;