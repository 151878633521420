/* Import Component React */
import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from "react-to-print";
import Api from "../Api";
import AuthVerify from "../AuthVerify";
import {useNavigate, useParams} from "react-router-dom";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, message, Popconfirm, Descriptions, Form, Input, Skeleton} from 'antd';

/* Import Component */
import {QuotationTableDetail} from "./QuotationTableItem";
import QuotationPrint from "./QuotationPrint";

/* Main Module */
function QuotationHistoryDetail() {
    /* Handle state */
    /*eslint-disable*/
    const [formCustomerInfo] = Form.useForm();
    const [quotationInfo, setQuotationInfo] = useState([]);
    const [quotationItemInfo, setQuotationItemInfo] = useState([]);
    const [quotationItem, setQuotationItem] = useState([]);
    const [loading, setLoading] = useState(false);
    let {uuid} = useParams();
    const navigate = useNavigate();

    /*
    * Initial table or fetch in first render.
    */
    useEffect(() => {
        fetchQuotation().finally();
    }, []);

    /*
    * Handle fetch data quotation for customer by user in status pending.
    */
    async function fetchQuotation() {
        setLoading(true);
        await Api.get(`/index-quotation-detail/${uuid}`).then(response => {
            const data = response.data.data;
            setQuotationInfo(data);
            setQuotationItemInfo(data);
            setQuotationItem(data.item)
            formCustomerInfo.setFieldsValue({
                customer_info: data.customer,
                valid_until_info: data.valid_until,
                created_by_info: data.created_by,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoading(false);
    }


    /*
    * Handle quotation update, make sure all field is corrected.
    */
    const handleQuotationUpdate = async () => {
        await Api.post(`/index-quotation-save/${quotationInfo.uuid}`, {
            status: "Pending",
            _method: 'PUT',
        }).then(response => {
            console.log(response);
            navigate(`/inventory/quotation`);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /* Handle Print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <Row className="justify-content-center">
            <AuthVerify />
            <Skeleton active loading={loading}>
                <Col xs sm lg={10}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="p-1 pt-0 pb-0 me-2 ms-2">
                                {/* Form info customer*/}
                                <Form layout="vertical" form={formCustomerInfo} requiredMark={false} className="mt-3">
                                    <Row>
                                        <Col xs sm lg={5}>
                                            <Form.Item label="Customer" name="customer_info" rules={[
                                                { required: true, message: "Please input customer" },
                                            ]}>
                                                <Input readOnly={true} className="text-dark" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs sm lg={3}>
                                            <Form.Item label="Valid Until" name="valid_until_info" rules={[
                                                { required: true, message: "Please input delivery date" }
                                            ]}>
                                                <Input readOnly={true} className="text-dark"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs sm lg={4}>
                                            <Form.Item label="Created By" name="created_by_info"  rules={[
                                                { required: true, message: "Please input user" },
                                            ]}>
                                                <Input readOnly={true} className="text-dark" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <QuotationTableDetail
                                    loading={loading}
                                    data={quotationItem}
                                />
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>

                <Col xs sm lg={2}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="header-form">No : {quotationInfo.quotation_number}</ListGroup.Item>
                            <ListGroup.Item className="item-instruction">
                                <Descriptions>
                                    <Descriptions.Item label="Status" className="m-0 p-1">{quotationInfo.status}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Total Item" className="m-0 p-1">{quotationItemInfo.total_item}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Quantity" className="m-0 p-1">{quotationItemInfo.quantity}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Sub Total" className="m-0 p-1">{quotationItemInfo.sub_total}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Margin" className="m-0 p-1">{quotationInfo.margin}%</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="PPN" className="m-0 p-1">{quotationInfo.tax}%</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="PPN Total" className="m-0 p-1">Rp. {quotationItemInfo.tax_total}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Total Price" className="m-0 p-1">{quotationItemInfo.total_price}</Descriptions.Item>
                                </Descriptions>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {/*Button Update*/}
                                <Popconfirm
                                    title="Update"
                                    description="Update quotation ?"
                                    onConfirm={() => handleQuotationUpdate()}
                                    onCancel={() =>  message.error("Update Canceled")}
                                    okText="Yes"
                                    cancelText="Cancel"
                                >
                                    <Button style={{ width: "100%", marginTop: "10px" }}>
                                        Update
                                    </Button>
                                </Popconfirm>

                                <Button type="primary" onClick={handlePrint} style={{ width: "100%", marginTop: "10px" }}>
                                    Print Quotation
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>

                {/* Component to be printed */}
                <div className="hide-print-content">
                    <QuotationPrint
                        ref={componentRef}
                        quotation={{quotationInfo, quotationItemInfo, quotationItem}}
                    />
                </div>
            </Skeleton>
        </Row>
    )
}

export default QuotationHistoryDetail;