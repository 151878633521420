/* Import component react */
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import style */
import {Card} from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import {Button, DatePicker, message, Popconfirm} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

/* Main Module */
function QuotationHistoryView() {
    /* Handle State */
    const [quotationHistory, setQuotationHistory] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;

    /* Handle Table */
    /*eslint-disable*/
    const columns = useMemo(() => [
        {
            accessorKey: "quotation_number",
            header: "Quotation #",
        },
        {
            accessorFn: (row) => dayjs(row.created_at).format('YYYY-MM-DD'),
            header: "Created At",
        },
        {
            accessorKey: "valid_until",
            header: "Valid Until",
        },
        {
            accessorKey: "status",
            header: "Status",
        },
        {
            accessorKey: "total_item",
            header: "Total Item",
        },
        {
            accessorKey: "quantity",
            header: "Quantity",
        },
        {
            accessorKey: "total_price",
            header: "Total Harga",
        },
        {
            accessorKey: "created_by",
            header: "Created By",
        }
    ]);


    /*
    * Fetch data in first render.
    */
    useEffect(() => {
        fetchData().finally();
    }, []);


    /*
    * Get data form backend.
    */
    async function fetchData() {
        setLoadingTable(true);
        await Api.get('index-quotation').then(response => {
            setQuotationHistory(response.data.data);
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
        setLoadingTable(false);
    }


    /*
    * Get data form backend by period.
    */
    async function fetchDataPeriod(values) {
        //Prepare variable
        const startDate = dayjs(values[0]).format('YYYY-MM-DD');
        const endDate = dayjs(values[1]).format('YYYY-MM-DD');

        setLoadingTable(true);
        await Api.get(`index-quotation-period/${startDate}/${endDate}`).then(response => {
            setQuotationHistory(response.data.data);
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
        setLoadingTable(false);
    }


    /*
    * Handle view sale period by range picker.
    */
    const handlePeriod = (values) => {
        values ? fetchDataPeriod(values) : fetchData();
    }


    /*
    * Move to trash quotation.
    */
    const moveToTrash = async (uuid, quotation_number) => {
        await Api.delete(`/index-quotation/${uuid}`).then(response => {
            message.success(`Quotation ${quotation_number} move to trash`);
            console.log(response);
            fetchData();
        }).catch(error => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loadingTable }}
                columns={columns}
                data={quotationHistory}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'quotation_number'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigate(`/inventory/quotation/detail/${row.original.uuid}`);
                    },
                })}
                renderRowActions={({ row}) => (
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => moveToTrash(row.original.uuid, row.original.quotation_number)}
                        onCancel={() => message.error('Delete Canceled')}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '450px',
                        maxHeight: '650px'
                    }
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <RangePicker placeholder={ ['Start date', 'End date'] } locale={locale} onChange={handlePeriod}/>
                )}
            />
        </Card>
    )
}

export default QuotationHistoryView;