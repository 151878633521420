/* Import component */
import React, {useEffect, useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, message, Skeleton} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/* Import child component */
import QuotationForm from "./QuotationForm";


/* Main component */
function QuotationUpdate(props) {
    const uuid = props.uuid;
    const [formQuotation] = Form.useForm(); //For reference form
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        fetchQuotation().finally();
        /*eslint-disable*/
    }, []);


    /*
    * Fetch data quotation index, for update.
    */
    async function fetchQuotation(){
        setLoadingForm(true);
        await Api.get(`index-quotation/${uuid}`).then(response => {
            const doc = response.data.data;
            formQuotation.setFieldsValue({
                customer_id: {value: doc.value, label: doc.label},
                valid_until: dayjs(doc.valid_until),
                margin: doc.margin,
                tax: doc.tax,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingForm(false);
    }



    /*
    * Handle update index quotation.
    */
    const updateQuotation = async (values) => {
        setLoadingButton(true);
        let customer_id;
        values.customer_id.value ? (customer_id = values.customer_id.value) : (customer_id = values.customer_id);

        await Api.post(`/index-quotation/${uuid}`, {
            company_id: props.companyId,
            customer_id: customer_id,
            valid_until: dayjs(values.valid_until).format('YYYY/MM/DD'),
            margin: values.margin,
            tax: values.tax,
            created_by: props.userId,
            _method: 'PUT',
        }).then(response => {
            message.success("Quotation updated");
            formQuotation.resetFields();
            formQuotation.setFieldsValue({
                margin: 0,
                tax: 0,
            });
            props.fetchQuotation();
            props.closeModal();
            fetchQuotation();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingButton(false);
    }


    return (
        <Form layout="vertical" onFinish={updateQuotation} form={formQuotation} initialValues={{ margin: 0, tax: 0 }} requiredMark={false} className="mt-3">
            <Skeleton active loading={loadingForm}>
                <QuotationForm
                    btnTitle={"Update"}
                    loadingBtn={loadingButton}
                    loading={false}
                />
            </Skeleton>
        </Form>
    )


}

export default QuotationUpdate;