/* Import Component React */
import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {Button} from "antd";

/* Min component */
function ReportSaleToExcel({apiData, fileName}) {
    /* Defined file type and extension */
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData); //Work Sheet
        const wb = { Sheets: { Sale: ws }, SheetNames: ["Sale"] }; //SheetName
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <Button type="primary" onClick={(e) => exportToExcel(apiData, fileName)}>Export</Button>
    );
}

export default ReportSaleToExcel;