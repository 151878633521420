/* Import Component React */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Col, Row} from "react-bootstrap";
import {Popconfirm, message, Button, Descriptions, Form, Space, Empty} from "antd";

/* Return the module */
function CompanyView() {
    //Prepare variable
    const [company, setCompany] = useState([]);
    const navigate = useNavigate();

    //Connection to api to get data
    async function fetchCompany() {
        //Await
        return await Api.get('/company');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        // localStorage.removeItem("company_logo");
        fetchCompany().then(response => {
            setCompany(response.data.data);
            localStorage.setItem('company_name', response.data.data.company_name);
            localStorage.setItem('company_logo', response.data.data.company_logo);
        }).catch(() => {
            setCompany([]);
        });
        /*eslint-disable*/
    }, []);

    //Navigate to form update
    const navigateUpdateCompany = () => {
        navigate(`/inventory/company/update/${company.uuid}`);
    }

    //Confirm delete
    const confirmDeleteCompany = async (uuid, company_name) => {
        //Sending request delete
        await Api.delete(`/company/${uuid}`);

        //Fetch new data department
        fetchCompany().then(response => {
            setCompany(response.data.data);
        });
        message.success(`Company ${company_name} is Deleted`);
    };

    //Show message when cancel delete
    const cancelDeleteCompany = (e) => {
        message.error('Delete Canceled');
    };

    const createDataCompany = () => {
        navigate('/inventory/company/create');
    }

    return (
        <>
            {company.company_name ? (
                <Row>
                    <AuthVerify />
                    <Col md lg={2}>
                        <Card>
                            <Card.Img variant="top" src={company.company_logo} className="p-2"/>
                            <Card.Body>
                                <Card.Title>{company.company_name}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md lg={9}>
                        <Card className="card-body">
                            <Descriptions title="Company Info" className="mt-2">
                                <Descriptions.Item label="Company Name">{company.company_name}</Descriptions.Item>
                                <Descriptions.Item label="Telephone">{company.company_contact}</Descriptions.Item>
                                <Descriptions.Item label="Email">{company.company_email}</Descriptions.Item>
                                <Descriptions.Item label="Address">
                                    {company.company_address}
                                </Descriptions.Item>
                            </Descriptions>
                            <hr className="my-3" />
                            <Form.Item>
                                <Space>
                                    <Button onClick={navigateUpdateCompany} type="primary" htmlType="button">
                                        Update
                                    </Button>
                                    <Popconfirm
                                        title="Delete"
                                        description="Are you sure to delete this?"
                                        onConfirm={() => confirmDeleteCompany(company.uuid, company.company_name)}
                                        onCancel={cancelDeleteCompany}
                                        okText="Yes"
                                        cancelText="No"
                                    > <Button danger > Delete </Button>
                                    </Popconfirm>
                                </Space>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Card className="card-body">
                    <AuthVerify />
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        imageStyle={{
                            height: 70,
                        }}
                        description={ <span> No Data Company </span> }
                    >
                        <Button type="primary" onClick={createDataCompany}>Create Now</Button>
                    </Empty>
                </Card>
            )}
        </>
    );
}

export default CompanyView;