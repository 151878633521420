/* Import Component React*/
import {Route, Routes} from "react-router-dom";

/* Import component module */
import CompanyView from "./CompanyView";
import CompanyCreate from "./CompanyCreate";
import CompanyUpdate from "./CompanyUpdate";


/* Return the module*/
function CompanyNav() {

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Company</h4>
            </div>
            <Routes>
                <Route path="/" element={<CompanyView/>} />
                <Route path="create" element={<CompanyCreate/>} />
                <Route path="update/:id" element={<CompanyUpdate/>} />
            </Routes>
        </>
    );
}

export default CompanyNav;