/* Import Component React */
import React, { useRef } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space} from "antd";

/* Import form */
import ManufactureForm from "./ManufactureForm";

/* Return the module */
function ManufactureCreate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State validation
    const [messageApi, contextHolder] = message.useMessage();

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `Manufacture ${value} is saved`,
        });
    }

    //Error message
    const errorStore = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Processing store document
    const storeManufacture = async (values) => {
        //Send data to backend
        await Api.post('/manufacture', {
            nama_manufaktur: values.nama_manufaktur,
            brand: values.brand,
        }).then(() => {
            success(values.nama_manufaktur);
            onReset();
        }).catch((error) => {
            errorStore(error.response.data.data);
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
    }

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            {contextHolder}
            <Col xs sm lg={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Entry Manufacture
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form layout="vertical" onFinish={storeManufacture} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
                                <ManufactureForm/>

                                <hr className="my-3" />
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <Button onClick={onReset} htmlType="button">
                                            Reset
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm lg={4}>
                {/*<Card>*/}
                {/*    <ListGroup variant="flush">*/}
                {/*        <ListGroup.Item className="header-form">Instruction</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Manufacture Name is a Required Field</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Brand is a Required Field</ListGroup.Item>*/}
                {/*    </ListGroup>*/}
                {/*</Card>*/}
            </Col>
        </Row>
    );
}

export default ManufactureCreate;