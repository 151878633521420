/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import child component */
import ReportStockSummary from "./ReportStockSummary";
import ReportStockDetail from "./ReportStockDetail";


/* Main component */
function ReportStockNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Report Stock</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/report-stock" eventKey="/inventory/report-stock" active={location.pathname === "/inventory/report-stock"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Summary
                        </Button>
                        <Button as={Link} to="/inventory/report-stock/detail" eventKey="/inventory/report-stock/detail" active={location.pathname === "/inventory/report-stock/detail"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Detail
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<ReportStockSummary/>} />
                <Route path="detail" element={<ReportStockDetail/>} />
            </Routes>
        </>
    );
}

export default ReportStockNav;