/* Import component react */
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import Api from '../Api';
import AuthVerify from "../AuthVerify";

/* Import style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, Descriptions, Form, Input, message, Skeleton} from "antd";

/* Import Component Child */
import DeliveryTableItem from "./DeliveryTableItem";
import DeliveryPrint from "./DeliveryPrint";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";

/* Main component */
function DeliveryHistoryDetail() {
    /* Handle state */
    /*eslint-disable*/
    const [formRefInfo] = Form.useForm();
    const [deliveryInfo, setDeliveryInfo] = useState([]);
    const [saleItem, setSaleItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    let {uuid} = useParams();

    /* Handle Print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    /*
    * Initial table or fetch in firsts render.
    */
    useEffect(() => {
        fetchDeliveryInfo().finally();
    }, []);


    /*
    * Handle fetch data delivery order by user in status pending.
    */
    async function fetchDeliveryInfo() {
        setLoading(true);
        await Api.get(`/do/${uuid}`).then(response => {
            const data = response.data.data;
            setDeliveryInfo(data);
            fetchItem(data.invoice_id);
            formRefInfo.setFieldsValue({
                customer_info: data.destination,
                delivery_date_info: data.delivery_date,
                created_by_info: data.created_by,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoading(false);
    }


    /*
    * Hande fetch data item for delivery order.
    */
    async function fetchItem(invoiceId) {
        setLoadingTable(true);
        await Api.get(`/index-sale-detail/${invoiceId}`).then(response => {
            const item = response.data.data;
            response.data.success === true ? setSaleItem(item.item) : setSaleItem([]);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingTable(false);
    }


    return (
        <Row className="justify-content-center">
            <AuthVerify />
            <Skeleton active loading={loading}>
                <Col xs sm lg={9}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="p-1 pt-0 pb-0 me-2 ms-2">

                                {/* Form info customer*/}
                                <Form layout="vertical" form={formRefInfo} requiredMark={false} className="mt-3">
                                    <Row>
                                        <Col xs sm lg={5}>
                                            <Form.Item label="Customer" name="customer_info">
                                                <Input readOnly={true} className="text-dark" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs sm lg={3}>
                                            <Form.Item label="Delivery Date" name="delivery_date_info" >
                                                <Input readOnly={true} className="text-dark"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs sm lg={4}>
                                            <Form.Item label="Created By" name="created_by_info">
                                                <Input readOnly={true} className="text-dark" />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Form>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                {/*Handle Table Item*/}
                                <DeliveryTableItem
                                    loading={loadingTable}
                                    data={saleItem}
                                />
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>

                <Col xs sm lg={3}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="header-form">No : {deliveryInfo.do_number}</ListGroup.Item>

                            <ListGroup.Item className="item-instruction">
                                <Descriptions>
                                    <Descriptions.Item label="Status" className="m-0 p-1">{deliveryInfo.status}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Delivery Date" className="m-0 p-1">{deliveryInfo.delivery_date}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Origin" className="m-0 p-1">{deliveryInfo.origin}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Destination" className="m-0 p-1">{deliveryInfo.destination}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Contact" className="m-0 p-1">{deliveryInfo.destination_contact}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Address" className="m-0 p-1">{''}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item className="m-0 p-1">{deliveryInfo.destination_address}</Descriptions.Item>
                                </Descriptions>
                            </ListGroup.Item>

                            <ListGroup.Item className="item-instruction">
                                <Descriptions>
                                    <Descriptions.Item label="Total Item" className="m-0 p-1">{deliveryInfo.total_item}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Quantity" className="m-0 p-1">{deliveryInfo.total_quantity}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Delivery Via" className="m-0 p-1">{deliveryInfo.delivery_via}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Delivery Cost" className="m-0 p-1">Rp. {deliveryInfo.delivery_cost}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Send By" className="m-0 p-1">{deliveryInfo.send_by}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Acknow By" className="m-0 p-1">{deliveryInfo.acknowledged_by}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="Remark" className="m-0 p-1">{''}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item className="m-0 p-1">{deliveryInfo.remark}</Descriptions.Item>
                                </Descriptions>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {/*Button Print Delivery*/}
                                <Button type="primary" onClick={handlePrint} style={{ width: "100%" }}>
                                    <FontAwesomeIcon icon={faPrint} className="me-1"/> Delivery Order
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Skeleton>

            {/* Component to be printed */}
            <div className="hide-print-content">
                <DeliveryPrint
                    ref={componentRef}
                    delivery={{deliveryInfo, saleItem}}
                />
            </div>
        </Row>
    )
}

export default DeliveryHistoryDetail;