/* Import component */
import {useCallback, useEffect, useRef, useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, Input, InputNumber, Select} from "antd";
import {Col, Row} from "react-bootstrap";

/* Return component */
function BarangForm() {
    //State document
    const [manufature, setManufacture] = useState([]);
    const [kategori, setKategori] = useState([]);
    const [subKategori, setSubKategori] = useState([]);
    const [jenisBarang, setJenisBarang] = useState([]);
    const [satuan, setSatuan] = useState([]);
    const [loadingManufacture, setLoadingManufacture] = useState(false);
    const [loadingKategori, setLoadingKategori] = useState(false);
    const [loadingSubKategori, setLoadingSubKategori] = useState(false);
    const [loadingJenisBarang, setLoadingJenisBarang] = useState(false);
    const [loadingSatuan, setLoadingSatuan] = useState(false);
    const  inputRef = useRef(true);

    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    //Connection to api to get list manufacture
    const fetchManufacture = useCallback(async () => {
        setLoadingManufacture(true);
        //Await
        const list = await Api.get('/manufacture/select');
        //..
        setManufacture(list.data.data);
        setLoadingManufacture(false);
        /* eslint-disable */
    }, [manufature]);

    //Connection to api to get list kategori
    const fetchKategori = useCallback(async () => {
        setLoadingKategori(true);
        //Await
        const list = await Api.get('/kategori/select');
        //..
        setKategori(list.data.data);
        setLoadingKategori(false);
        /* eslint-disable */
    }, [kategori]);

    //Connection to api to get list sub kategori
    const fetchSubKategori = useCallback(async () => {
        setLoadingSubKategori(true);
        //Await
        const list = await Api.get('/sub-kategori/select');
        //..
        setSubKategori(list.data.data);
        setLoadingSubKategori(false);
        /* eslint-disable */
    }, [subKategori]);

    //Connection to api to get list jenis barang
    const fetchJenisBarang = useCallback(async () => {
        setLoadingJenisBarang(true);
        //Await
        const list = await Api.get('/jenis-barang/select');
        //..
        setJenisBarang(list.data.data);
        setLoadingJenisBarang(false);
        /* eslint-disable */
    }, [jenisBarang]);

    //Connection to api to get list uom
    const fetchUom = useCallback(async () => {
        setLoadingSatuan(true);
        //Await
        const list = await Api.get('/uom/select');
        //..
        setSatuan(list.data.data);
        setLoadingSatuan(false);
        /* eslint-disable */
    }, [satuan]);

    return (
        <>
            <Row>
                <Col xs sm lg={4}>
                    <Form.Item label="Part Number" name="part_number" rules={[
                        { required: true, message: "Please input part number" },
                        { min: 3, message: "Part number name must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={100} ref={inputRef}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={8}>
                    <Form.Item label="Nama Barang" name="nama_barang" rules={[
                        { required: true, message: "Please input nama barang" },
                        { min: 3, message: "Nama barang must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={150} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Manufacture / Brand" name="manufaktur_id" rules={[ {required: true, message: "Please select a manufature"} ]}>
                        <Select placeholder="Select a manufacture" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={fetchManufacture} onFocus={fetchManufacture} loading={loadingManufacture} options={manufature} />

                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Kategori" name="kategori_id" rules={[ {required: true, message: "Please select a kategori"} ]}>
                        <Select placeholder="Select a kategori" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={fetchKategori} onFocus={fetchKategori} loading={loadingKategori} options={kategori} />

                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Sub Kategori" name="sub_kategori_id" rules={[ {required: true, message: "Please select a sub kategori"} ]}>
                        <Select placeholder="Select a sub kategori" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={fetchSubKategori} onFocus={fetchSubKategori} loading={loadingSubKategori} options={subKategori} />

                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Jenis Barang" name="jenis_barang_id" rules={[ {required: true, message: "Please select a jenis barang"} ]}>
                        <Select placeholder="Select a jenis barang" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={fetchJenisBarang} onFocus={fetchJenisBarang} loading={loadingJenisBarang} options={jenisBarang} />

                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Satuan" name="satuan_id" rules={[ {required: true, message: "Please select a satuan"} ]}>
                        <Select placeholder="Select a kategori" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={fetchUom} onFocus={fetchUom} loading={loadingSatuan} options={satuan} />

                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    {/* Space */}
                </Col>
                <Col xs sm lg={12}>
                    <Form.Item label="Harga Jual" name="harga_jual" rules={[
                        { required: true, message: "Please input harga jual" },
                    ]}>
                        <InputNumber
                            style={{ width: "100%" }}
                            prefix="Rp."
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default BarangForm;