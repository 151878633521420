/* Import Component React */
import { useEffect, useMemo, useState } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Popconfirm, message, Button} from "antd";
import { MaterialReactTable } from "material-react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRecycle} from "@fortawesome/free-solid-svg-icons";

/* Time management variable */
import moment from "moment/moment";
import {Card} from "react-bootstrap";

/* Return the module */
function UomViewTrash() {

    /* Handle state and variable */
    const [uoms, setUoms] = useState([]);
    const columns = useMemo(
        () => [
            {
                accessorKey: "uom",
                header: "Uom",
            },{
                accessorFn: (row) => moment(row.deleted_at).format('lll'),
                header: "Deleted At",
            }
            // eslint-disable-next-line
        ], []
    );

    /* Handle notification */
    //Show message when cancel delete
    const cancelDeleteUom = (e) => {
        message.error('Void Deleted');
    };

    //Show message when cancel restore
    const cancelRestoreUom = (e) => {
        message.error('Void Restore');
    };

    /* Handle connection, data and action */
    //Connection to api to get data
    async function fetchUomTrash() {
        //Await
        return await Api.get('/uom/trash');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        fetchUomTrash().then(response => {
            setUoms(response.data.data);
        }).catch(() => {
            setUoms([]);
        });
    }, []);

    //Confirm delete
    const confirmDeleteUom = async (uuid, uom) => {
        //Sending request delete
        await Api.delete(`/uom/${uuid}`);

        //Fetch new data
        fetchUomTrash().then(response => {
            setUoms(response.data.data);
        });
        message.success(`Uom ${uom} deleted`);
    };

    //Restore data
    const confirmRestoreUom = async (uuid, uom) => {
        //Sending request restore
        await Api.get(`/uom/restore/${uuid}`);

        //Fetch new data
        fetchUomTrash().then(response => {
            setUoms(response.data.data);
        });
        message.success(`Manufacture ${uom} is restored`);
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                columns={columns}
                data={uoms}
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact' }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                renderRowActions={({ row}) => (
                    <>
                        <Popconfirm
                            title="Restore"
                            description="Are you sure to restore this?"
                            onConfirm={() => confirmRestoreUom(row.original.uuid, row.original.uom)}
                            onCancel={cancelRestoreUom}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small"> <FontAwesomeIcon icon={faRecycle} /> </Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Delete"
                            description="Are you sure to delete this?"
                            onConfirm={() => confirmDeleteUom(row.original.uuid, row.original.uom)}
                            onCancel={cancelDeleteUom}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small" > Delete </Button>
                        </Popconfirm>
                    </>

                )}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
            />
        </Card>
    );
}

export default UomViewTrash;