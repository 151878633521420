/* Import Component React */
import React, {useEffect, useRef, useState} from 'react';
import Api from "../Api";
import AuthVerify from "../AuthVerify";
import {useReactToPrint} from "react-to-print";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, message, Popconfirm, Descriptions, Modal, Form, Input, Result, InputNumber} from 'antd';

/* Import Component */
import SaleItemOperation from "./SaleItemOperation";
import SaleCreate from "./SaleCreate";
import SaleUpdate from "./SaleUpdate";
import SaleInvoicePrint from "./SaleInvoicePrint";
import dayjs from "dayjs";

/* Return component */
function SaleView() {
    /* Handle state and variable */
    /*eslint-disable*/
    const userId = localStorage.getItem('uuid_user');
    const companyId = localStorage.getItem('company_id');
    const [formCustomerInfo] = Form.useForm();
    const [formPayment] = Form.useForm();
    const [saleInfo, setSaleInfo] = useState([]);
    const [saleItemInfo, setSaleItemInfo] = useState([]);
    const [saleItem, setSaleItem] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [openModalCustomer, setOpenModalCustomer] = useState(false);
    const [modalResult, setModalResult] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    /* Formatting Number */
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-ID').format(number);
    }


    /*
    * Fetch chart in firsts render
    */
    useEffect(() => {
        fetchSale().finally();
        formPayment.setFieldsValue({
            payment_received: 0,
        });
    }, []);


    /*
    * Handle fetch data customer
    */
    //Get Invoice Customer
    async function fetchSale() {
        //Await
        await Api.get(`/index-sale-customer/${userId}`).then(response => {
            const data = response.data.data;
            setSaleInfo(data);
            fetchItem().finally();
            formCustomerInfo.setFieldsValue({
                customer_info: data.customer,
                tanggal_penjualan_info: data.tanggal_penjualan,
                created_by_info: data.created_by
            });
            data.nomor_invoice ? setDisableButton(false) : setDisableButton(true);
        }).catch((error) => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
        //...
    }


    /*
    * Handle fetch data item.
    */
    async function fetchItem() {
        //Await
        setLoadingTable(true);
        await Api.get(`/index-sale-chart/${userId}`).then(response => {
            const doc = response.data.data;
            response.data.success === true ? (
                setSaleItemInfo(doc),
                setSaleItem(doc.item)
            ) : (
                setSaleItemInfo([]),
                setSaleItem([])
            )
        }).catch((error) => {
            console.log(error);
            error === "Unauthenticated." ?
                message.error("Unauthenticated, Please Login.")
                :
                message.error("Invalid Server Error")
        });
        //..
        setLoadingTable(false);
    }


    /*
    * Handle close modal customer.
    */
    const handleCloseModalCustomer = () => setOpenModalCustomer(false);


    /*
    * Handle Sale Checkout, make sure all field is corrected.
    */
    const handleCheckout = async () => {
        const payment_received = formPayment.getFieldValue('payment_received');

        if (payment_received === null) {
            message.error("Please input payment received");
        } else {
            await Api.post(`/index-sale-payment/${saleInfo.uuid}`, {
                tanggal_penjualan: dayjs(saleInfo.tanggal_penjualan).format('YYYY/MM/DD'),
                due_date: dayjs(saleInfo.due_date).format('YYYY/MM/DD'),
                payment_received: payment_received,
                _method: 'PUT',
            }).then(response => {
                console.log(response);
                setModalResult(true);
                fetchSale();
            }).catch(error => {
                console.log(error);
                message.error("Invalid server error");
            });
        }
    }

    /*
    * Handle close modal result.
    */
    const handleModalResult = async () => {
        await Api.post(`/index-sale-checkout/${saleInfo.uuid}`, {
            _method: 'PUT',
        }).then(response => {
            console.log(response);
            setModalResult(false);
            formPayment.setFieldsValue({
                payment_received: 0,
            })
            fetchSale().finally();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /*
    * Handle cancel sale
    * cancel sale is deleting data index sale and sale item
    */
    const confirmCancelSale = async () => {
        //Send request delete
        await Api.delete(`/index-sale-cancel/${saleInfo.uuid}`).then(() => {
            message.success(`Sale is canceled`);
            fetchSale();
        }).catch(error => {
            console.log(error);
            message.error("Invalid error, please try again");
        });
    }

    /* Handle Print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            <Col xs sm lg={9} >
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="p-1 pt-0 pb-0 me-2 ms-2">
                            {/* Form info customer*/}
                            <Form layout="vertical" form={formCustomerInfo} requiredMark={false} className="mt-3">
                                <Row>
                                    <Col xs sm lg={5}>
                                        <Form.Item label="Customer" name="customer_info" onClick={() => setOpenModalCustomer(true)}  rules={[
                                            { required: true, message: "Please input customer" },
                                        ]}>
                                            <Input readOnly={true} className="text-dark" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs sm lg={3}>
                                        <Form.Item label="Transaction Date" name="tanggal_penjualan_info" rules={[
                                            { required: true, message: "Please input delivery date" }
                                        ]}>
                                            <Input readOnly={true} className="text-dark"/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs sm lg={4}>
                                        <Form.Item label="Created By" name="created_by_info"  rules={[
                                            { required: true, message: "Please input user" },
                                        ]}>
                                            <Input readOnly={true} className="text-dark" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            {/*Handle Create Sale*/}
                            <Modal
                                title="Sale For"
                                open={openModalCustomer}
                                onCancel={handleCloseModalCustomer}
                                width={500}
                                style={{
                                    top: 57,
                                }}
                                footer={[
                                    /*Disable Button */
                                ]}
                            >
                                {
                                    saleInfo.nomor_invoice ?
                                        <SaleUpdate
                                            companyId={companyId}
                                            userId={userId}
                                            uuid={saleInfo.uuid}
                                            fetchSale={fetchSale}
                                            closeModal={handleCloseModalCustomer}
                                        />
                                        :
                                        <SaleCreate
                                            companyId={companyId}
                                            userId={userId}
                                            fetchSale={fetchSale}
                                            closeModal={handleCloseModalCustomer}
                                        />
                                }
                            </Modal>

                            {/*Modal result*/}
                            <Modal
                                open={modalResult}
                                onCancel={handleModalResult}
                                width={500}
                                style={{
                                    top: 57,
                                }}
                                footer={[
                                    /*Disable footer for personal button*/
                                ]}
                            >
                                <Result
                                    className="pt-2 pb-0"
                                    status="success"
                                    title="Sale Successfully Saved!"
                                    subTitle={[
                                        <p className="mb-0">
                                            Invoice Number : {saleInfo.nomor_invoice}
                                        </p>,
                                        <p>
                                            Please Check history For Detail
                                        </p>
                                    ]}
                                    extra={[
                                        <>
                                            <Button onClick={handlePrint}>Print Invoice</Button>
                                            <Button type="primary" onClick={handleModalResult}>Close Order</Button>
                                        </>
                                    ]}
                                />
                            </Modal>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <SaleItemOperation
                                loading={loadingTable}
                                saleItem={saleItem}
                                saleUuid={saleInfo.uuid}
                                margin={saleInfo.margin}
                                fetchItem={fetchItem}
                                disableButton={disableButton}
                            />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>

            <Col xs sm lg={3}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">No : {saleInfo.nomor_invoice}</ListGroup.Item>
                        <ListGroup.Item className="item-instruction">
                            <Descriptions>
                                <Descriptions.Item label="Type" className="m-0 p-1">{saleInfo.selling_type}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Total Item" className="m-0 p-1">{saleItemInfo.total_item}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Quantity" className="m-0 p-1">{saleItemInfo.quantity}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Sub Total" className="m-0 p-1">{saleItemInfo.sub_total}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Margin%" className="m-0 p-1">{saleInfo.margin}%</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="PPN%" className="m-0 p-1">{saleInfo.tax}%</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="PPN" className="m-0 p-1">{saleItemInfo.tax_total}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Total" className="m-0 p-1">{saleItemInfo.total_price}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Transaksi" className="m-0 p-1">{saleInfo.tanggal_penjualan}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Jatuh Tempo" className="m-0 p-1">{saleInfo.due_date}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Payment" className="m-0 p-1">Rp. {saleInfo.payment_received && formatNumber(saleInfo.payment_received)}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Bill" className="m-0 p-1">Rp. {saleInfo.bill && formatNumber(saleInfo.bill)}</Descriptions.Item>
                            </Descriptions>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            {/* Form info customer*/}
                            <Form layout="vertical" form={formPayment} requiredMark={false}>
                                <Form.Item label="Payment Received" name="payment_received" className="mb-0" rules={[
                                    { required: true, message: "Please input payment received" }
                                ]}>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        prefix="Rp."
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                </Form.Item>
                            </Form>

                            <Popconfirm
                                title="Checkout"
                                description="Are you sure to checkout this?"
                                onConfirm={() => handleCheckout()}
                                onCancel={() =>  confirmCancelSale()}
                                okText="Yes"
                                cancelText="Cancel"
                                disabled={disableButton}
                            >
                                <Button type="primary" style={{ width: "100%", marginTop: "10px" }}>
                                    Checkout
                                </Button>
                            </Popconfirm>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>

            {/* Component to be printed */}
            <div className="hide-print-content">
                <SaleInvoicePrint
                    ref={componentRef}
                    invoice={{saleInfo, saleItemInfo, saleItem}}
                />
            </div>
        </Row>
    )
}
export default SaleView;