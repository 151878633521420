/* Import Component React */
import React, { useRef } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space} from "antd";

/* Import form */
import CustomerForm from "./CustomerForm";

/* Return the module */
function CustomerCreate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State validation
    const [messageApi, contextHolder] = message.useMessage();

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `${value} is saved`,
        });
    }

    //Error message
    const errorStore = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Processing store document
    const store = async (values) => {
        //Send data to backend
        await Api.post('/customer', {
            customer_name: values.customer_name,
            contact: values.contact,
            email: values.email,
            address:  values.address,
        }).then(() => {
            success(values.customer_name);
            onReset();
        }).catch((error) => {
            console.log(error);
            errorStore('Invalid server error, please wait');
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
    }

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            {contextHolder}
            <Col xs sm lg={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Entry Data Customer
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form layout="vertical" onFinish={store} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
                                <CustomerForm/>

                                <hr className="my-3" />
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <Button onClick={onReset} htmlType="button">
                                            Reset
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm lg={4}>
                {/*Space*/}
            </Col>
        </Row>
    );
}

export default CustomerCreate;