/* Import Component React */
import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from "react-to-print";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, message, Popconfirm, Descriptions, Form, Input, Modal, Result} from 'antd';

/* Import Component */
import QuotationItemOperation from "./QuotationItemOperation";
import QuotationCreate from "./QuotationCreate";
import QuotationUpdate from "./QuotationUpdate";
import QuotationPrint from "./QuotationPrint";


function QuotationView() {
    /* Handle state */
    /*eslint-disable*/
    const userId = localStorage.getItem('uuid_user');
    const companyId = localStorage.getItem('company_id');
    const [formCustomerInfo] = Form.useForm();
    const [quotationInfo, setQuotationInfo] = useState([]);
    const [quotationItemInfo, setQuotationItemInfo] = useState([]);
    const [quotationItem, setQuotationItem] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [openModalCustomer, setOpenModalCustomer] = useState(false);
    const [modalResult, setModalResult] = useState(false);
    const [disableButton, setDisableButton] = useState(false);


    /*
    * Initial table or fetch in first render.
    */
    useEffect(() => {
        fetchQuotation().finally();
    }, []);

    /*
    * Handle fetch data quotation for customer by user in status pending.
    */
    async function fetchQuotation() {
        await Api.get(`/index-quotation-customer/${userId}`).then(response => {
            const data = response.data.data;
            setQuotationInfo(data);
            fetchItem();
            formCustomerInfo.setFieldsValue({
                customer_info: data.customer,
                valid_until_info: data.valid_until,
                created_by_info: data.created_by,
            });
            data.quotation_number ? setDisableButton(false) : setDisableButton(true);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /*
    * Handle fetch data item quotation.
    */
    async function fetchItem() {
        setLoadingTable(true);
        await Api.get(`/index-quotation-item/${userId}`).then(response => {
            const doc = response.data.data
            response.data.success === true ? (
                setQuotationItemInfo(doc),
                setQuotationItem(doc.item),
                setDisableButton(false)
            ) : (
                setQuotationItemInfo([]),
                setQuotationItem([]),
                setDisableButton(true)
            )
        }).catch(error => {
            console.log(error);
        });
        setLoadingTable(false);
    }


    /*
    * Handle close modal customer.
    */
    const handleCloseModalCustomer = () => setOpenModalCustomer(false);


    /*
    * Handle quotation proses, make sure all field is corrected.
    */
    const handleQuotationProcess = async () => {
        await Api.post(`/index-quotation-save/${quotationInfo.uuid}`, {
            status: "Saved",
            _method: 'PUT',
        }).then(response => {
            setModalResult(true);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /*
    * Handle close modal result.
    */
    const handleCloseModalResult = () => {
        setModalResult(false);
        fetchQuotation().finally();
    }


    /*
    * Handle cancel quotation process.
    */
    const handleCancelQuotation = async () => {
        await Api.delete(`/index-quotation-cancel/${quotationInfo.uuid}`).then(response => {
            console.log(response);
            message.success("Quotation canceled");
            fetchQuotation();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /* Handle Print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    return (
        <Row className="justify-content-center">
            <AuthVerify />
            <Col xs sm lg={10}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="p-1 pt-0 pb-0 me-2 ms-2">
                            {/* Form info customer*/}
                            <Form layout="vertical" form={formCustomerInfo} requiredMark={false} className="mt-3">
                                <Row>
                                    <Col xs sm lg={5}>
                                        <Form.Item label="Customer" name="customer_info" onClick={() => setOpenModalCustomer(true)}  rules={[
                                            { required: true, message: "Please input customer" },
                                        ]}>
                                            <Input readOnly={true} className="text-dark" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs sm lg={3}>
                                        <Form.Item label="Valid Until" name="valid_until_info" rules={[
                                            { required: true, message: "Please input valid until date" }
                                        ]}>
                                            <Input readOnly={true} className="text-dark"/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs sm lg={4}>
                                        <Form.Item label="Created By" name="created_by_info"  rules={[
                                            { required: true, message: "Please input user" },
                                        ]}>
                                            <Input readOnly={true} className="text-dark" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            {/*Handle Create Quotation*/}
                            <Modal
                                title="Quotation For"
                                open={openModalCustomer}
                                onCancel={handleCloseModalCustomer}
                                width={500}
                                style={{
                                    top: 57,
                                }}
                                footer={[
                                    /*Disable Button */
                                ]}
                            >
                                {
                                    quotationInfo.quotation_number ?
                                        <QuotationUpdate
                                            companyId={companyId}
                                            userId={userId}
                                            uuid={quotationInfo.uuid}
                                            fetchQuotation={fetchQuotation}
                                            closeModal={handleCloseModalCustomer}
                                        />
                                        :
                                        <QuotationCreate
                                            companyId={companyId}
                                            userId={userId}
                                            fetchQuotation={fetchQuotation}
                                            closeModal={handleCloseModalCustomer}
                                        />
                                }
                            </Modal>

                            {/*Modal result*/}
                            <Modal
                                open={modalResult}
                                onCancel={handleCloseModalResult}
                                width={500}
                                style={{
                                    top: 57,
                                }}
                                footer={[
                                    /*Disable footer for personal button*/
                                ]}
                            >
                                <Result
                                    className="pt-2 pb-0"
                                    status="success"
                                    title="Quotation Successfully Saved!"
                                    subTitle={[
                                        <p className="mb-0">
                                            Quotation Number : {quotationInfo.quotation_number}
                                        </p>,
                                        <p>
                                            Please Check history For Detail
                                        </p>
                                    ]}
                                    extra={[ <Button onClick={handlePrint}>Print Quotation</Button> ]}
                                />
                            </Modal>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <QuotationItemOperation
                                loading={loadingTable}
                                quotationItem={quotationItem}
                                quotationUuid={quotationInfo.uuid}
                                margin={quotationInfo.margin}
                                fetchItem={fetchItem}
                                disableButton={disableButton}
                            />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>

            <Col xs sm lg={2}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">No : {quotationInfo.quotation_number}</ListGroup.Item>
                        <ListGroup.Item className="item-instruction">
                            <Descriptions>
                                <Descriptions.Item label="Status" className="m-0 p-1">{quotationInfo.status}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Total Item" className="m-0 p-1">{quotationItemInfo.total_item}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Quantity" className="m-0 p-1">{quotationItemInfo.quantity}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Sub Total" className="m-0 p-1">{quotationItemInfo.sub_total}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Margin" className="m-0 p-1">{quotationInfo.margin}%</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="PPN" className="m-0 p-1">{quotationInfo.tax}%</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="PPN Total" className="m-0 p-1">Rp. {quotationItemInfo.tax_total}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Total Price" className="m-0 p-1">{quotationItemInfo.total_price}</Descriptions.Item>
                            </Descriptions>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            {/*Button Process*/}
                            <Popconfirm
                                title="Process"
                                description="Save quotation ?"
                                onConfirm={() => handleQuotationProcess()}
                                onCancel={() =>  handleCancelQuotation()}
                                okText="Yes"
                                cancelText="Cancel"
                                disabled={disableButton}
                            >
                                <Button type="primary" style={{ width: "100%", marginTop: "10px" }}>
                                    Process
                                </Button>
                            </Popconfirm>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>

            {/* Component to be printed */}
            <div className="hide-print-content">
                <QuotationPrint
                    ref={componentRef}
                    quotation={{quotationInfo, quotationItemInfo, quotationItem}}
                />
            </div>
        </Row>
    )
}

export default QuotationView;