//import axios
import axios from 'axios';
const token = localStorage.getItem('token');

if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}


const Api = axios.create({
    //set default endpoint API
   //baseURL: 'http://localhost:8000/api' //Local
     baseURL: 'https://api.nusantaradigitalsolution.com/api' //Server
})

export default Api
