/* Import component */
import {useRef, useEffect} from "react";
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

/* Import Style */
import {DatePicker, Form, Input, InputNumber, Select, Descriptions} from "antd";
import {Col, ListGroup, Row} from "react-bootstrap";

/* Return component */
export function StockForm(props) {
    const inputRef = useRef(true);

    //Set Fokus Form
    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    return (
        <>
            <Row>
                <Col xs sm lg={5}>
                    <Form.Item label="Supplier" name="supplier_id" rules={[ {required: true, message: "Please select a supplier"} ]}>
                        <Select placeholder="Select a supplier" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={props.supplier} onFocus={props.supplier} loading={props.loadingSupplier} options={props.optionsSupplier} ref={inputRef}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={3}>
                    <Form.Item label="Tanggal Masuk" name="tanggal_masuk" rules={[
                        { required: true, message: "Please input tanggal masuk" }
                    ]}>
                        <DatePicker locale={locale} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    {/*Space*/}
                </Col>

                <Col xs sm lg={8}>
                    <Form.Item label="Reference Barang" name="barang_id" rules={[ {required: true, message: "Please select a reference"} ]}>
                        <Select placeholder="Select a reference" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={props.barang} onFocus={props.barang} onChange={props.satuan} loading={props.loadingBarang} options={props.optionsBarang} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    {/*<Form ref={formRef}>*/}
                        <Form.Item label="Satuan" name="satuan">
                            <Input disabled={true} className="text-dark"/>
                        </Form.Item>
                    {/*</Form>*/}
                </Col>

                <Col xs sm lg={4}>
                    <Form.Item label="Quantity" name="qty_in" rules={[
                        { required: true, message: "Please input quantity" }
                    ]}>
                        <InputNumber style={{ width: "100%" }}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Quantity isi" name="qty_fill" rules={[
                        { required: true, message: "Please input quantity isi" }
                    ]}>
                        <InputNumber style={{ width: "100%" }}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Harga Beli" name="harga_beli" rules={[
                        { required: true, message: "Please input harga beli" },
                    ]}>
                        <InputNumber
                            style={{ width: "100%" }}
                            prefix="Rp."
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}


/* Component Info*/
export function Info(props) {

    //Component
    return (
        <ListGroup variant="flush">
            <ListGroup.Item className="header-form">Info Barang</ListGroup.Item>

            <ListGroup.Item className="item-instruction">
                <Descriptions>
                    <Descriptions.Item label="Nama Barang" className="m-0 p-1">{props.dataBarang.nama_barang}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Part Number" className="m-0 p-1">{props.dataBarang.part_number}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Manufaktur" className="m-0 p-1">{props.dataBarang.manufaktur}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Satuan" className="m-0 p-1">{props.dataBarang.satuan}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Kategori" className="m-0 p-1">{props.dataBarang.kategori}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Sub Kategori" className="m-0 p-1">{props.dataBarang.sub_kategori}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Harga Jual" className="m-0 p-1">{props.dataBarang.harga_jual}</Descriptions.Item>
                </Descriptions>
            </ListGroup.Item>

        </ListGroup>
    )

}