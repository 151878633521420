/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import BarangView from "./BarangView";
import BarangCreate from "./BarangCreate";
import BarangUpdate from "./BarangUpdate";

/* Return the module*/
function BarangNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Data Barang</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/data-barang" eventKey="/inventory/data-barang" active={location.pathname === "/inventory/data-barang"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                        <Button as={Link} to="/inventory/data-barang/create" eventKey="/inventory/data-barang/create" active={location.pathname === "/inventory/data-barang/create"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Create
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<BarangView/>} />
                <Route path="create" element={<BarangCreate/>} />
                <Route path="update/:id" element={<BarangUpdate/>} />
            </Routes>
        </>
    );
}

export default BarangNav;