/* Import Component React */
import React, { useRef } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space} from "antd";

/* Import form */
import BarangForm from "./BarangForm";

/* Return the module */
function BarangCreate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State validation
    const [messageApi, contextHolder] = message.useMessage();

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `Data barang ${value} is saved`,
        });
    }

    //Error message
    const errorStore = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Processing store document
    const store = async (values) => {
        //Send data to backend
        await Api.post('/data-barang', {
            part_number: values.part_number,
            nama_barang: values.nama_barang,
            manufaktur_id: values.manufaktur_id,
            satuan_id:  values.satuan_id,
            kategori_id: values.kategori_id,
            sub_kategori_id: values.sub_kategori_id,
            jenis_barang_id: values.jenis_barang_id,
            harga_jual: values.harga_jual
        }).then(() => {
            success(values.nama_barang);
            onReset();
        }).catch((error) => {
            errorStore(error.response.data.data);
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
    }

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            {contextHolder}
            <Col xs sm lg={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Entry Data Barang
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form layout="vertical" onFinish={store} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
                                <BarangForm/>

                                <hr className="my-3" />
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <Button onClick={onReset} htmlType="button">
                                            Reset
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm lg={4}>
                {/*<Card>*/}
                {/*    <ListGroup variant="flush">*/}
                {/*        <ListGroup.Item className="header-form">Instruction</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">All Field is a Required Field</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Jenis Barang is Like Original, or OEM Item</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Harga Jual is a Price For Sale Product to Customer</ListGroup.Item>*/}
                {/*    </ListGroup>*/}
                {/*</Card>*/}
            </Col>
        </Row>
    );
}

export default BarangCreate;