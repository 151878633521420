/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import JenisBarangViewCreate from "./JenisBarangViewCreate";


/* Return the module*/
function JenisBarangNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Jenis Barang</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/jenis-barang" eventKey="/inventory/jenis-barang" active={location.pathname === "/inventory/jenis-barang"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<JenisBarangViewCreate/>} />
            </Routes>
        </>
    );
}

export default JenisBarangNav;