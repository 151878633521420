/* Import Component React */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import { Card } from "react-bootstrap";
import {Popconfirm, message, Button} from "antd";
import { MaterialReactTable } from "material-react-table";

/* Time management variable */
import moment from "moment/moment";

/* Return the module */
function SupplierView() {
    //Prepare variable
    const [supplier, setSupplier] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "nama_supplier",
                header: "Supplier",
            },{
                accessorKey: "no_telp",
                header: "Telepon",
            },{
                accessorKey: "email",
                header: "Email",
            },{
                accessorKey: "alamat",
                header: "Alamat",
            },{
                accessorFn: (row) => moment(row.created_at).format('lll'),
                header: "Created At",
            }
            // eslint-disable-next-line
        ], []
    );

    //Connection to api to get data
    async function fetchData() {
        //Await
        return await Api.get('/supplier');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        fetchData().then(response => {
            setSupplier(response.data.data);
            setLoading(false);
        }).catch(() => {
            setSupplier([]);
        });
    }, []);

    //Navigate to form update
    const navigateUpdate = (id) => {
        navigate(`/inventory/supplier/update/${id}`);
    }

    //Confirm delete data
    const confirmDelete = async (uuid, nama_supplier) => {
        //Sending request delete
        await Api.delete(`/supplier/${uuid}`);

        //Fetch new data department
        fetchData().then(response => {
            setSupplier(response.data.data);
        });
        message.success(`${nama_supplier} is Deleted`);
    };

    //Show message when cancel delete
    const cancelDelete = (e) => {
        message.error('Delete Canceled');
    };

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={supplier}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                renderRowActions={({ row}) => (
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDelete(row.original.uuid, row.original.nama_supplier)}
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigateUpdate(row.original.uuid);
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
            />
        </Card>
    );
}

export default SupplierView;