/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import UomViewCreate from "./UomViewCreate";
import UomViewTrash from "./UomViewTrash";


/* Return the module*/
function UomNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Unit Of Measurements</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/uom" eventKey="/inventory/uom" active={location.pathname === "/inventory/uom"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button as={Link} to="/inventory/uom/trash" eventKey="/inventory/uom/trash" active={location.pathname === "/inventory/uom/trash"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Trash
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<UomViewCreate/>} />
                <Route path="trash" element={<UomViewTrash/>} />
            </Routes>
        </>
    );
}

export default UomNav;