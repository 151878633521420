/* Import Component React */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import { Card } from "react-bootstrap";
import {Popconfirm, message, Button} from "antd";
import { MaterialReactTable } from "material-react-table";

/* Time management variable */
import moment from "moment/moment";

/* Return the module */
function BarangView() {
    //Prepare variable
    const [barang, setBarang] = useState([]);
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "doc_number",
                header: "Kode Barang",
            },{
                accessorKey: "nama_barang",
                header: "Nama Barang",
            },{
                accessorKey: "part_number",
                header: "Part Number",
            },{
                accessorKey: "manufaktur_rel.nama_manufaktur",
                header: "Manufacture",
            },{
                accessorKey: "kategori_rel.nama_kategori",
                header: 'Kategori',
            },{
                accessorKey: "sub_kategori_rel.nama_sub_kategori",
                header: 'Sub Kategori',
            },{
                accessorKey: "jenis_barang_rel.nama_jenis_barang",
                header: 'Jenis Barang',
            },{
                accessorKey: "satuan_rel.uom",
                header: 'Sub Kategori',
            },{
                accessorKey: "harga_jual",
                header: 'Harga Jual',
            },{
                accessorFn: (row) => moment(row.created_at).format('lll'),
                header: "Created At",
            }
            // eslint-disable-next-line
        ], []
    );

    //Connection to api to get data
    async function fetchDataBarang() {
        //Await
        return await Api.get('/data-barang');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        fetchDataBarang().then(response => {
            setBarang(response.data.data);
        }).catch(() => {
            setBarang([]);
        });
    }, []);

    //Navigate to form update
    const navigateUpdateBarang = (id) => {
        navigate(`/inventory/data-barang/update/${id}`);
    }

    //Confirm delete data barang
    const confirmDeleteBarang = async (uuid, doc_number) => {
        //Sending request delete
        await Api.delete(`/data-barang/${uuid}`);

        //Fetch new data department
        fetchDataBarang().then(response => {
            setBarang(response.data.data);
        });
        message.success(`Kode barang ${doc_number} is Deleted`);
    };

    //Show message when cancel delete
    const cancelDeleteBarang = (e) => {
        message.error('Delete Canceled');
    };

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                columns={columns}
                data={barang}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['nama_barang'], right: ['sub_total', 'mrt-row-actions'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                renderRowActions={({ row}) => (
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteBarang(row.original.uuid, row.original.doc_number)}
                        onCancel={cancelDeleteBarang}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigateUpdateBarang(row.original.uuid);
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
            />
        </Card>
    );
}

export default BarangView;