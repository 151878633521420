/* Import Component React */
import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from "react-to-print";
import Api from "../Api";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {Button, message, Popconfirm, Descriptions, Form, Input, Modal, Result} from 'antd';

/* Import Component */
import AuthVerify from "../AuthVerify";
import DeliveryTableItem from "./DeliveryTableItem";
import DeliveryCreate from "./DeliveryCreate";
import DeliveryUpdate from "./DeliveryUpdate";
import DeliveryPrint from "./DeliveryPrint";

/* Main Component */
function DeliveryView() {
    /* Handle state */
    /*eslint-disable*/
    const userId = localStorage.getItem('uuid_user');
    const companyId = localStorage.getItem('company_id');
    const [formRefInfo] = Form.useForm();
    const [deliveryInfo, setDeliveryInfo] = useState([]);
    const [saleItem, setSaleItem] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [openModalDelivery, setOpenModalDelivery] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [modalResult, setModalResult] = useState(false);
    const [doNumberSaved, setDoNumberSaved] = useState(null);


    /*
    * Initial table or fetch in firsts render.
    */
    useEffect(() => {
        fetchDeliveryInfo().finally();
    }, []);


    /*
    * Handle fetch data delivery order by user in status pending.
    */
    async function fetchDeliveryInfo() {
        await Api.get(`/do-pending/${userId}`).then(response => {
            const data = response.data.data;
            setDeliveryInfo(data);
            fetchItem(data.invoice_id);
            formRefInfo.setFieldsValue({
                customer_info: data.destination,
                delivery_date_info: data.delivery_date,
                created_by_info: data.created_by,
            });
            data.destination ? setDisableButton(false) : setDisableButton(true);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /*
    * Hande fetch data item for delivery order.
    */
    async function fetchItem(invoiceId) {
        setLoadingTable(true);
        await Api.get(`/index-sale-detail/${invoiceId}`).then(response => {
            const item = response.data.data;
            response.data.success === true ? setSaleItem(item.item) : setSaleItem([]);

        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingTable(false);
    }


    /*
    * Handle close modal delivery
    */
    const handleCloseModalDelivery = () => {
        setOpenModalDelivery(false);
    }


    /*
    * Handle delivery process, make sure all filed is corected.
    */
    const handleDeliveryProcess = async () => {
        await Api.post(`/do-saved/${deliveryInfo.uuid}`, {
            status: "Saved",
            _method: "PUT",
        }).then(response => {
            setDoNumberSaved(response.data.data.do_number);
            setModalResult(true);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /*
    * Handle close modal.
    */
    const closeModalResult = () => {
        setModalResult(false);
        setDoNumberSaved(null);
        fetchDeliveryInfo().finally();
    }


    /*
    * Handle cancel delivery process, make sure all filed is corected.
    */
    const handleCancelDeliveryProcess = async () => {
        await Api.delete(`/do-cancel/${deliveryInfo.uuid}`).then(response => {
            console.log(response);
            message.success("Delivery order canceled");
            fetchDeliveryInfo();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /* Handle Print */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <Row className="justify-content-center">
            <AuthVerify />
            <Col xs sm lg={9}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="p-1 pt-0 pb-0 me-2 ms-2">
                            {/*Handle Create Delivery*/}
                            <Modal
                                title="Delivery Order"
                                open={openModalDelivery}
                                onCancel={handleCloseModalDelivery}
                                width={500}
                                style={{
                                    top: 57,
                                }}
                                footer={[
                                    /*Disable Button */
                                ]}
                            >
                                {
                                    deliveryInfo.do_number ?
                                        <DeliveryUpdate
                                            closeModal={handleCloseModalDelivery}
                                            fetchDelivery={fetchDeliveryInfo}
                                            userInfo={{companyId, userId}}
                                            uuidDo={deliveryInfo.uuid}
                                        />
                                        :
                                        <DeliveryCreate
                                            closeModal={handleCloseModalDelivery}
                                            fetchDelivery={fetchDeliveryInfo}
                                            userInfo={{companyId, userId}}
                                        />
                                }
                            </Modal>

                            {/*Modal result*/}
                            <Modal
                                open={modalResult}
                                onCancel={closeModalResult}
                                width={500}
                                style={{
                                    top: 57,
                                }}
                                footer={[
                                    /*Disable footer for personal button*/
                                ]}
                            >
                                <Result
                                    className="pt-2 pb-0"
                                    status="success"
                                    title="Delivery Order Successfully Saved!"
                                    subTitle={[
                                        <p className="mb-0">
                                            Delivery Order Number : {doNumberSaved}
                                        </p>,
                                        <p>
                                            Please Check history For Detail
                                        </p>
                                    ]}
                                    extra={[ <Button onClick={handlePrint}>Print Delivery Order</Button> ]}
                                />
                            </Modal>

                            {/* Form info customer*/}
                            <Form layout="vertical" form={formRefInfo} requiredMark={false} className="mt-3">
                                <Row>
                                    <Col xs sm lg={5}>
                                        <Form.Item label="Customer" name="customer_info" onClick={() => setOpenModalDelivery(true)}  rules={[
                                            { required: true, message: "Please input customer" },
                                        ]}>
                                            <Input readOnly={true} className="text-dark" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs sm lg={3}>
                                        <Form.Item label="Delivery Date" name="delivery_date_info" rules={[
                                            { required: true, message: "Please input delivery date" }
                                        ]}>
                                            <Input readOnly={true} className="text-dark"/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs sm lg={4}>
                                        <Form.Item label="Created By" name="created_by_info"  rules={[
                                            { required: true, message: "Please input user" },
                                        ]}>
                                            <Input readOnly={true} className="text-dark" />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Form>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            {/*Handle Table Item*/}
                            <DeliveryTableItem
                                loading={loadingTable}
                                data={saleItem}
                            />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>

            <Col xs sm lg={3}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">No : {deliveryInfo.do_number}</ListGroup.Item>

                        <ListGroup.Item className="item-instruction">
                            <Descriptions>
                                <Descriptions.Item label="Status" className="m-0 p-1">{deliveryInfo.status}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Delivery Date" className="m-0 p-1">{deliveryInfo.delivery_date}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Origin" className="m-0 p-1">{deliveryInfo.origin}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Destination" className="m-0 p-1">{deliveryInfo.destination}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Contact" className="m-0 p-1">{deliveryInfo.destination_contact}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Address" className="m-0 p-1">{''}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item className="m-0 p-1">{deliveryInfo.destination_address}</Descriptions.Item>
                            </Descriptions>
                        </ListGroup.Item>

                        <ListGroup.Item className="item-instruction">
                            <Descriptions>
                                <Descriptions.Item label="Total Item" className="m-0 p-1">{deliveryInfo.total_item}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Quantity" className="m-0 p-1">{deliveryInfo.total_quantity}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Delivery Via" className="m-0 p-1">{deliveryInfo.delivery_via}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Delivery Cost" className="m-0 p-1">Rp. {deliveryInfo.delivery_cost}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Send By" className="m-0 p-1">{deliveryInfo.send_by}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Acknow By" className="m-0 p-1">{deliveryInfo.acknowledged_by}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="Remark" className="m-0 p-1">{''}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item className="m-0 p-1">{deliveryInfo.remark}</Descriptions.Item>
                            </Descriptions>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            {/*Button Process*/}
                            <Popconfirm
                                title="Process"
                                description="Process delivery order?"
                                onConfirm={() => handleDeliveryProcess()}
                                onCancel={() =>  handleCancelDeliveryProcess()}
                                okText="Yes"
                                cancelText="Cancel"
                                disabled={disableButton}
                            >
                                <Button type="primary" style={{ width: "100%", marginTop: "10px" }}>
                                    Process
                                </Button>
                            </Popconfirm>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>

            {/* Component to be printed */}
            <div className="hide-print-content">
                <DeliveryPrint
                    ref={componentRef}
                    delivery={{deliveryInfo, saleItem}}
                />
            </div>
        </Row>
    )

}

export default DeliveryView;