/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import QuotationView from "./QuotationView";
import QuotationHistoryView from "./QuotationHistoryView";
import QuotationHistoryDetail from "./QuotationHistoryDetail";
import QuotationHistoryTrash from "./QuotationHistoryTrash";

function QuotationNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Quotation</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link} to="/inventory/quotation" eventKey="/inventory/quotation" active={location.pathname === "/inventory/quotation"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Quotation
                        </Button>
                        <Button as={Link}  to="/inventory/quotation/history" eventKey="/inventory/quotation/history" active={location.pathname === "/inventory/quotation/history"} variant="outline-secondary" size="sm" className="btn-navigation">
                            History
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button as={Link} to="/inventory/quotation/trash" eventKey="/inventory/quotation/trash" active={location.pathname === "/inventory/quotation/trash"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Trash
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<QuotationView/>} />
                <Route path="history" element={<QuotationHistoryView />} />
                <Route path="detail/:uuid" element={<QuotationHistoryDetail />} />
                <Route path="trash" element={<QuotationHistoryTrash />} />
            </Routes>
        </>
    );
}

export default QuotationNav;