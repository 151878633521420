/* Import Style */
import { Form, Input } from "antd";

/* Return component */
function ManufactureForm() {
    return (
        <>
            <Form.Item label="Manufacture" name="nama_manufaktur" rules={[
                { required: true, message: "Please input manufaktur" },
                { min: 3, message: "Manufaktur name must have min 3 character" }
            ]}>
                <Input showCount maxLength={100} />
            </Form.Item>

            <Form.Item label="Brand" name="brand" rules={[
                { required: true, message: "Please input brand" },
                { min: 3, message: "Brand must have min 3 character" }
            ]}>
                <Input showCount maxLength={100} />
            </Form.Item>
        </>
    )
}

export default ManufactureForm;