/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import SupplierView from "./SupplierView";
import SupplierCreate from "./SupplierCreate";
import SupplierUpdate from "./SupplierUpdate";

/* Return the module*/
function SupplierNav() {
    //Location path
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Supplier</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/supplier" eventKey="/inventory/supplier" active={location.pathname === "/inventory/supplier"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                        <Button as={Link} to="/inventory/supplier/create" eventKey="/inventory/supplier/create" active={location.pathname === "/inventory/supplier/create"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Create
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<SupplierView/>} />
                <Route path="create" element={<SupplierCreate/>} />
                <Route path="update/:id" element={<SupplierUpdate/>} />
            </Routes>
        </>
    );
}

export default SupplierNav;