/* Import Component React */
import React, {useRef, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../Api";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Skeleton, Space} from "antd";

/* Import form */
import SupplierForm from "./SupplierForm";

/* Return the module */
function SupplierUpdate() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    //State message
    const [messageApi, contextHolder] = message.useMessage();
    //Defined navigate
    const navigate = useNavigate();
    //Get params id
    let { id } = useParams();
    const [loading, setLoading] = useState(true);

    /* Handle notification */
    //Success message
    const success = (value) => {
        messageApi.open({
            type: 'success',
            content: `${value} is updated`,
        }).then(() => {
            onReset();
        });
    }

    //Error message
    const errorUpdate = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    /* Handle connection, data and action */
    //Connection to api for find data
    async function fetchData() {
        //Await
        return await Api.get(`/supplier/${id}`);
        //...
    }

    //Hook data for form
    /* eslint-disable */
    useEffect(() => {
        fetchData().then(response => {
            //Get response data
            const doc = response.data.data

            //Assign doc to field
            formRef.current?.setFieldsValue({
                nama_supplier: doc.nama_supplier,
                no_telp: doc.no_telp,
                email: doc.email,
                alamat: doc.alamat,
            });
            setLoading(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            cancel();
        })
    }, [id]);

    //Processing update document
    const update = async (values) => {

        //Send data to backend
        await Api.post(`/supplier/${id}`, {
            nama_supplier: values.nama_supplier,
            no_telp: values.no_telp,
            email: values.email,
            alamat:  values.alamat,
            _method: 'PUT',
        }).then(() => {
            success(values.nama_supplier);
            setTimeout(() => {
                cancel();
            }, 1000);
        }).catch((error) => {
            errorUpdate("Invalid server error, please try again");
        });
    }

    //Reset form
    const onReset = () => {
        formRef.current?.resetFields();
    }

    //cancel and back to main menu
    const cancel = () => {
        navigate('/inventory/supplier')
    }

    return (
        <Row className="justify-content-md-center">
            {contextHolder}
            <Col xs sm={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Update Data Supplier
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card.Body>
                                <Form layout="vertical" onFinish={update} ref={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-4">
                                    <Skeleton active loading={loading}>
                                        <SupplierForm/>
                                    </Skeleton>

                                    <hr className="my-3" />
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                            <Button onClick={cancel} htmlType="button">
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card.Body>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col xs sm={4}>
                {/*<Card>*/}
                {/*    <ListGroup variant="flush">*/}
                {/*        <ListGroup.Item className="header-form">Instruction</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">All Field is a Required Field</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Jenis Barang is Like Original, or OEM Item</ListGroup.Item>*/}
                {/*        <ListGroup.Item className="item-instruction">Harga Jual is a Price For Sale Product to Customer</ListGroup.Item>*/}
                {/*    </ListGroup>*/}
                {/*</Card>*/}
            </Col>
        </Row>
    );
}

export default SupplierUpdate;