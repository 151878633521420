/* Import component React and Style */
import React from "react";
import {Routes, Route} from "react-router-dom";

/* Import component */
import LoginView from "./Authentication/LoginView";
import InventoryDash from "./InventoryDash";


function App() {
    return (
        <Routes>
            <Route path="/" element={<LoginView/>} />
            <Route path="/inventory/*" element={<InventoryDash/>} />
        </Routes>
    )
}

export default App;
