/* Import component react */
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import style */
import {Card} from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import {Button, DatePicker, message, Popconfirm} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

/* Main module */
function DeliveryHistoryView() {
    /* Handle state */
    const [deliveryHistory, setDeliveryHistory] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;

    /* Handle table */
    /*eslint-disable*/
    const columns = useMemo(() => [
        {
            accessorKey: "do_number",
            header: "Delivery Order #",
        },
        {
            accessorKey: "delivery_date",
            header: "Delivery Date",
        },
        {
            accessorKey: "invoice_number",
            header: "Invoice #",
        },
        {
            accessorKey: "origin",
            header: "Origin",
        },
        {
            accessorKey: "destination",
            header: "Destination",
        },
        {
            accessorKey: "delivery_via",
            header: "Delivery Via",
        },
        {
            accessorKey: "total_item",
            header: "Item",
        },
        {
            accessorKey: "total_quantity",
            header: "Total Qty",
        },
        {
            accessorKey: "created_by",
            header: "Created By",
        },
        {
            accessorKey: "status",
            header: "Status",
        },
    ]);

    /*
    * Fetch data in first render.
    */
    useEffect(() => {
        fetchData().finally();
    }, []);


    /*
    * Get data form backend.
    */
    async function fetchData() {
        setLoadingTable(true);
        await Api.get('/do').then(response => {
            setDeliveryHistory(response.data.data);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingTable(false);
    }


    /*
    * Get data form backend by period.
    */
    async function fetchDataPeriod(values) {
        //Prepare variable
        const startDate = dayjs(values[0]).format('YYYY-MM-DD');
        const endDate = dayjs(values[1]).format('YYYY-MM-DD');

        setLoadingTable(true);
        await Api.get(`/do-period/${startDate}/${endDate}`).then(response => {
            setDeliveryHistory(response.data.data);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingTable(false);
    }


    /*
    * Handle view sale period by range picker.
    */
    const periodDelivery = (values) => {
        values ? fetchDataPeriod(values) : fetchData();
    }


    /*
    * Move to trash delivery order
    */
    const moveToTrash = async (uuid, do_number) => {
        await Api.delete(`/do/${uuid}`).then(response => {
            message.success(`Delivery ${do_number} move to trash`);
            console.log(response);
            fetchData();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loadingTable }}
                columns={columns}
                data={deliveryHistory}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'do_number', 'delivery_date'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigate(`/inventory/delivery/detail/${row.original.uuid}`);
                    },
                })}
                renderRowActions={({ row}) => (
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => moveToTrash(row.original.uuid, row.original.do_number)}
                        onCancel={() => message.error('Delete Canceled')}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <RangePicker placeholder={ ['Start date', 'End date'] } locale={locale} onChange={periodDelivery}/>
                )}
            />
        </Card>
    )
}

export default DeliveryHistoryView;