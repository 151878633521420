/* Import Component React */
import React, {useMemo} from 'react';

/* Import Style */
import {MaterialReactTable} from "material-react-table";
import {Button, message, Popconfirm} from "antd";

function SaleTableItem(props) {
    //Table
    const columns = useMemo(
        () => [
            {
                accessorKey: "nama_barang",
                header: "Nama Barang",
            },{
                accessorKey: "part_number",
                header: "Part Number",
            },{
                accessorKey: "manufaktur",
                header: "Manufaktur",
            },{
                accessorKey: "satuan",
                header: "Satuan",
            },{
                accessorKey: "stock_out",
                header: "Qty",
            },{
                accessorKey: "total_price",
                header: "Total Harga",
            },{
                accessorKey: "diskon",
                header: "Diskon",
            },{
                accessorKey: "sub_total",
                header: "Sub Total",
            }
            // eslint-disable-next-line
        ], []
    );

    return (
        <>
            <MaterialReactTable
                state={{ isLoading: props.loading }}
                columns={columns}
                data={props.data}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['nama_barang'], right: ['sub_total', 'mrt-row-actions'] } }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowActions={true}
                positionActionsColumn="last"
                renderRowActions={({ row}) => (
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => props.delete(row.original.uuid, row.original.nama_barang)}
                        onCancel={() => message.error('Delete Canceled')}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        props.doubleClick();
                        props.setId(row.original.uuid);
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '350px',
                        maxHeight: '450px'
                    }
                }}

                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <Button type="primary" htmlType="button" style={{ width: "10%" }} disabled={props.disableButton} onClick={props.openModal}>
                        Add Item
                    </Button>
                )}
            />
        </>
    )
}

export default SaleTableItem;


export function SaleTableItemDetail(props) {
    //Table
    const columns = useMemo(
        () => [
            {
                accessorKey: "nama_barang",
                header: "Nama Barang",
            },{
                accessorKey: "part_number",
                header: "Part Number",
            },{
                accessorKey: "manufaktur",
                header: "Manufaktur",
            },{
                accessorKey: "satuan",
                header: "Satuan",
            },{
                accessorKey: "stock_out",
                header: "Qty",
            },{
                accessorKey: "total_price",
                header: "Total Harga",
            },{
                accessorKey: "diskon",
                header: "Diskon",
            },{
                accessorKey: "sub_total",
                header: "Sub Total",
            }
            // eslint-disable-next-line
        ], []
    );

    return (
        <>
            <MaterialReactTable
                state={{ isLoading: props.loading }}
                columns={columns}
                data={props.data}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['nama_barang'], right: ['sub_total', 'mrt-row-actions'] } }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '350px',
                        maxHeight: '450px'
                    }
                }}
            />
        </>
    )
}