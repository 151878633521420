/* Import component */
import React, {useCallback, useState} from "react";
import Api from "../Api";

/* Import Style */
import {Button, DatePicker, Form, InputNumber, Select, Skeleton, message} from "antd";

/* Import Time Component */
import locale from "antd/es/date-picker/locale/id_ID";

function QuotationForm(props) {
    const [listCustomer, setListCustomer] = useState([]); //List customer
    const [loadingListCustomer, setLoadingListCustomer] = useState(false) //Loading list customer


    /*
    * Handle list customer, Trigger form select, Fetch data customer.
    */
    //Handle select customer
    const handleListCustomer = useCallback(async () => {
        fetchListCustomer().finally();
        /* eslint-disable */
    }, [listCustomer]);

    //Fetch list customer
    async function fetchListCustomer() {
        //Await
        setLoadingListCustomer(true);
        await Api.get('/customer/select').then(response => {
            setListCustomer(response.data.data);
            setLoadingListCustomer(false);
        }).catch((error) => {
            console.log(error);
            message.error("Invalid server error, please try again");
            setListCustomer([]);
        });
    };

    return (
        <Skeleton active loading={props.loading}>
            <Form.Item label="Reference Customer" name="customer_id" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListCustomer} loading={loadingListCustomer} options={listCustomer} />
            </Form.Item>

            <Form.Item label="Valid Until" name="valid_until" rules={[
                { required: true, message: "Please input valid until" }
            ]}>
                <DatePicker placeholder="Valid Until" locale={locale} style={{ width: "100%"}}/>
            </Form.Item>

            <Form.Item label="Margin" name="margin">
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="PPN" name="tax">
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <hr/>
            <Button type="primary" htmlType="submit" style={{ width: "100%"}} loading={props.loadingBtn}>
                {props.btnTitle}
            </Button>
        </Skeleton>
    )
}

export default QuotationForm;