/* Import component */
import React, {useEffect, useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, message} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/* Import child component */
import SaleForm from "./SaleForm";

/* Main Component */
function SaleUpdate(props) {
    const uuid = props.uuid;
    const [formRefCustomer] = Form.useForm();
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        fetchSale().finally();
        /*eslint-disable*/
    }, []);


    /*
    * Fetch data sale index, for update.
    */
    async function fetchSale() {
        setLoadingForm(true);
        await Api.get(`index-sale/${uuid}`).then(response => {
            const doc = response.data.data;
            formRefCustomer.setFieldsValue({
                customer_id: {value: doc.value, label: doc.label},
                tanggal_penjualan: [dayjs(doc.tanggal_penjualan), dayjs(doc.due_date)],
                selling_type: doc.selling_type,
                margin: doc.margin,
                tax: doc.tax,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingForm(false);
    }

    /*
    * Handle update index sale
    */
    const updateSale = async (values) => {
        setLoadingButton(true);
        //Prepare value options
        let customer_id
        values.customer_id.value ? (customer_id = values.customer_id.value) : (customer_id = values.customer_id);

        //Send data to backend
        await Api.post(`/index-sale/${uuid}`, {
            company_id: props.companyId,
            customer_id: customer_id,
            tanggal_penjualan: dayjs(values.tanggal_penjualan[0]).format('YYYY/MM/DD'),
            due_date: dayjs(values.tanggal_penjualan[1]).format('YYYY/MM/DD'),
            selling_type: values.selling_type,
            margin: values.margin,
            tax: values.tax,
            created_by: props.userId,
            _method : 'PUT',
        }).then(() => {
            message.success("Customer Updated");
            formRefCustomer.resetFields();
            formRefCustomer.setFieldsValue({
                margin: 0,
                tax: 0,
            })
            props.fetchSale();
            props.closeModal();
            fetchSale();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error, please try again later");
        });
        setLoadingButton(false);
    }


    return (
        <Form layout="vertical" onFinish={updateSale} form={formRefCustomer} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
            <SaleForm
                btnTitle={"Update"}
                loadingBtn={loadingButton}
                loading={loadingForm}
            />
        </Form>
    )
}

export default SaleUpdate;