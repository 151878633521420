/* Import component */
import {useEffect, useRef} from "react";

/* Import Style */
import {Form, Input} from "antd";
import {Col, Row} from "react-bootstrap";
import TextArea from "antd/es/input/TextArea";

/* Return component */
function CustomerForm() {
    const  inputRef = useRef(true);

    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    return (
        <>
            <Row>
                <Col xs sm lg={12}>
                    <Form.Item label="Customer" name="customer_name" rules={[
                        { required: true, message: "Please input customer" },
                        { min: 3, message: "Customer name must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={150} ref={inputRef}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={6}>
                    <Form.Item label="Contact" name="contact" rules={[
                        { required: true, message: "Please input contact" },
                        { min: 8, message: "Contact must have min 8 character" }
                    ]}>
                        <Input showCount maxLength={13} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={6}>
                    <Form.Item label="Email" name="email" rules={[
                        { required: true, message: "Please input email" },
                        { min: 10, message: "Email must have min 10 character" }
                    ]}>
                        <Input showCount maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={12}>
                    <Form.Item label="Alamat" name="address" rules={[
                        { required: true, message: "Please input company alamat" },
                        { min: 10, message: "Customer alamat must have min 10 character" }
                    ]} >
                        <TextArea showCount maxLength={300} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default CustomerForm;