/* Import Component React */
import React, {useMemo} from 'react';

/* Import Style */
import {MaterialReactTable} from "material-react-table";

function DeliveryTableItem(props) {
    /*
    * Defined columns table
    */
    const columns = useMemo(() => [
        {
            accessorKey: "nama_barang",
            header: "Nama Barang",
        },
        {
            accessorKey: "part_number",
            header: "Part Number",
        },
        {
            accessorKey: "manufaktur",
            header: "Manufaktur",
        },
        {
            accessorKey: "satuan",
            header: "Satuan",
        },
        {
            accessorKey: "stock_out",
            header: "Qty",
        },
        {
            accessorKey: "invoice_number",
            header: "Invoice Reference",
        }
            // eslint-disable-next-line
        ], []
    );


    return (
        <>
            <MaterialReactTable
                state={{ isLoading: props.loading }}
                columns={columns}
                data={props.data}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['nama_barang', 'part_number'] } }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '350px',
                        maxHeight: '450px'
                    }
                }}
            />
        </>
    )
}

export default DeliveryTableItem;