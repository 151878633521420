/* Import Component React */
import React, { useEffect, useMemo, useState } from "react";
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import { Card } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";

/* Return the module */
function StockView() {
    //Prepare variable
    const [stock, setStock] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "nomor_barang",
                header: "Kode Barang",
            },{
                accessorKey: "nama_barang",
                header: "Nama Barang",
            },{
                accessorKey: "nomor_part",
                header: "Part Number",
            },{
                accessorKey: "harga",
                header: "Harga",
            },{
                accessorKey: "stock",
                header: "Stock",
            },{
                accessorKey: "manufaktur",
                header: "Manufaktur",
            },{
                accessorKey: "kategori",
                header: "Kategori",
            },{
                accessorKey: "sub_kategori",
                header: "Sub Kategori",
            },
            // eslint-disable-next-line
        ], []
    );

    //Connection to api to get data
    async function fetchData() {
        //Await
        return await Api.get('/stock');
        //...
    }

    //Hook data in first rendering
    useEffect(() => {
        fetchData().then(response => {
            setStock(response.data.data);
            setLoading(false);
        }).catch(() => {
            setStock([]);
        });
    }, []);

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={stock}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', /*columnPinning: { left: ['nama_barang'] }*/  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigate(`/inventory/stock/detail/${row.original.uuid}`);
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
            />
        </Card>
    );
}

export default StockView;