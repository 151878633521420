/* Import component */
import {useEffect, useRef} from "react";

/* Import Style */
import {Form, Input} from "antd";
import {Col, Row} from "react-bootstrap";
import TextArea from "antd/es/input/TextArea";

/* Return component */
function SupplierForm() {
    const  inputRef = useRef(true);

    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    return (
        <>
            <Row>
                <Col xs sm lg={12}>
                    <Form.Item label="Supplier" name="nama_supplier" rules={[
                        { required: true, message: "Please input supplier" },
                        { min: 3, message: "Supplier name must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={100} ref={inputRef}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={6}>
                    <Form.Item label="Telepon" name="no_telp" rules={[
                        { required: true, message: "Please input telepon" },
                        { min: 8, message: "Telepon must have min 8 character" }
                    ]}>
                        <Input showCount maxLength={13} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={6}>
                    <Form.Item label="Email" name="email" rules={[
                        { required: true, message: "Please input email" },
                        { min: 10, message: "Email must have min 10 character" }
                    ]}>
                        <Input showCount maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs sm lg={12}>
                    <Form.Item label="Alamat" name="alamat" rules={[
                        { required: true, message: "Please input company address" },
                        { min: 10, message: "Company address must have min 10 character" }
                    ]} >
                        <TextArea showCount maxLength={300} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default SupplierForm;