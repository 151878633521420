import React from "react";
import CompanyInfo from "../ComponentPrint/CompanyInfo";

/*Import Style*/
import '../Print.css';
import {Card, CardGroup} from "react-bootstrap";

const SaleInvoicePrint = React.forwardRef((props, ref) => {
    const saleInfo = props.invoice.saleInfo;
    const saleItemInfo = props.invoice.saleItemInfo;
    const saleItem = props.invoice.saleItem;

    /* Formatting Number */
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-ID').format(number);
    }


    return (
        <div ref={ref} className="print-page">
            <div className="body-page">
                <CompanyInfo /> {/*Header*/}
                <hr className="print-line"/>

                <div className="header">
                    <h1 className="header-h1">Invoice</h1>
                </div>
                <article>

                    {/*Customer Info*/}
                    <address>
                        <p style={{marginBottom: "0.5em", fontWeight: "bold", textDecoration: "underline"}}>
                            Billed To :
                        </p>
                        <p style={{marginBottom: "0.25em"}}> {saleInfo.customer} </p>
                        <p style={{marginBottom: "0.5em"}}> {saleInfo.customer_contact} </p>

                        <p style={{marginBottom: "0.25em", fontWeight: "bold", textDecoration: "underline"}}>
                            Address :
                        </p>
                        <p> {saleInfo.customer_address} </p>
                    </address>

                    {/*Invoice Info*/}
                    <table className="main meta">
                        <tbody>
                            <tr>
                                <th><span>Invoice #</span></th>
                                <td><span>{saleInfo.nomor_invoice}</span></td>
                            </tr>
                            <tr>
                                <th><span>Delivery Order #</span></th>
                                <td><span>{ saleInfo.delivery_order }</span></td>
                            </tr>
                            <tr>
                                <th><span>Type</span></th>
                                <td><span>{saleInfo.selling_type}</span></td>
                            </tr>
                            <tr>
                                <th><span>Status</span></th>
                                <td><span>{saleInfo.payment_received < saleInfo.bill ? "Menunggu Pembayaran" : "Payment Success"}</span></td>
                            </tr>
                            <tr>
                                <th><span>Date Transaction</span></th>
                                <td><span>{saleInfo.tanggal_penjualan}</span></td>
                            </tr>
                            <tr>
                                <th><span>Due Date</span></th>
                                <td><span>{saleInfo.due_date}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="print-line"/>

                    {/*Item Detail*/}
                    <table className="main inventory" style={{ breakInside: "auto"}}>
                        <thead>
                            <tr>
                                <th width="5%"><span>#</span></th>
                                <th width="20%"><span>Nama Barang</span></th>
                                <th width="20%"><span>Part Number</span></th>
                                <th width="10%"><span>Satuan</span></th>
                                <th width="8%"><span>Qty</span></th>
                                <th width="15%"><span>Total Harga</span></th>
                                <th width="7%"><span>Diskon</span></th>
                                <th width="15%"><span>Sub Total</span></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            saleItem.length > 0 ?
                                saleItem.map((data, index) => (
                                    <tr>
                                        <td><span>{ index+1 }</span></td>
                                        <td><span>{ data.nama_barang }</span></td>
                                        <td><span>{ data.part_number }</span></td>
                                        <td><span>{ data.satuan }</span></td>
                                        <td><span>{ data.stock_out }</span></td>
                                        <td><span>{ data.total_price }</span></td>
                                        <td><span>{ data.diskon }</span></td>
                                        <td><span>{ data.sub_total }</span></td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="alert alert-info mb-0">
                                                Item tidak tersedia
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>

                    {/*Item Total Info*/}
                    <table className="main item" style={{ breakInside: "avoid" }}>
                        <tbody>
                            <tr>
                                <th><span>Total Item</span></th>
                                <td><span>{saleItemInfo.total_item}</span></td>
                            </tr>
                            <tr>
                                <th><span>Total Quantity</span></th>
                                <td><span>{saleItemInfo.quantity}</span></td>
                            </tr>
                        </tbody>
                    </table>

                    {/*Balance Info*/}
                    <table className="main balance" style={{ breakInside: "avoid" }}>
                        <tbody>
                            <tr>
                                <th><span>Sub Total</span></th>
                                <td><span>{saleItemInfo.sub_total}</span></td>
                            </tr>
                            <tr>
                                <th><span>PPN {saleInfo.tax}%</span></th>
                                <td><span>{saleItemInfo.tax_total}</span></td>
                            </tr>
                            <tr>
                                <th><span>Total Amount</span></th>
                                <td><span>{saleItemInfo.total_price}</span></td>
                            </tr>
                            <tr>
                                <th><span>Payment Success</span></th>
                                <td><span>{formatNumber(saleInfo.payment_received)}</span></td>
                            </tr>
                            <tr>
                                <th><span>Bill</span></th>
                                <td><span>{formatNumber(saleInfo.bill)}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </article>


                <aside className="footer-print" style={{ breakInside: "avoid" }}>
                    <h1><span>Payment Info</span></h1>
                    <div className="text-note">
                        <p style={{marginBottom: "0.5em"}}>
                            <span style={{fontWeight: "bold" }}>
                                Bank Name :
                            </span> <span className="me-5">BCA</span>
                        </p>
                        <p style={{marginBottom: "0.5em"}}>
                            <span style={{fontWeight: "bold" }}>
                                Rek Number :
                            </span> <span className="me-5">7615997676</span> <br/>
                        </p>
                        <p style={{marginBottom: "3em"}}>
                            <span style={{fontWeight: "bold" }}>
                                Rek Name :
                            </span> <span className="me-5">Nusantara Digital Solution</span>
                        </p>
                    </div>
                </aside>

                <CardGroup className="text-center font-footer-info " style={{ breakInside: "avoid" }}>
                    <Card>
                        <Card.Header>Mengetahui</Card.Header>
                        <Card.Body style={{ minHeight: "100px" }}>
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ minHeight: "30px" }}>

                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Header>{localStorage.getItem('company_name')}</Card.Header>
                        <Card.Body>
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ minHeight: "30px" }}>
                            {saleInfo.created_by}
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Header>Customer</Card.Header>
                        <Card.Body>
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{ minHeight: "30px" }}>
                            {saleInfo.customer}
                        </Card.Footer>
                    </Card>
                </CardGroup>


                {/*<aside className="footer-print" style={{ breakInside: "avoid" }}>*/}
                {/*    <h1><span>Additional Notes</span></h1>*/}
                {/*    <div className="text-note">*/}
                {/*        <p>A finance charge of 1.5% will be made on unpaid balances after 30 days.</p>*/}
                {/*    </div>*/}
                {/*</aside>*/}
            </div>
        </div>
    )
});

export default SaleInvoicePrint;
