/* Import component */
import React, {useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, message} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/* Import child component */
import QuotationForm from "./QuotationForm";


/* Main component */
function QuotationCreate(props) {
    const [formQuotation] = Form.useForm(); //For reference form
    const [loadingButton, setLoadingButton] = useState(false);


    /*
    * Handle store index quotation.
    */
    const storeQuotation = async (values) => {
        setLoadingButton(true);
        await Api.post('/index-quotation', {
            company_id: props.companyId,
            customer_id: values.customer_id,
            valid_until: dayjs(values.valid_until).format('YYYY/MM/DD'),
            margin: values.margin,
            tax: values.tax,
            created_by: props.userId,
        }).then(response => {
            message.success("Quotation order created");
            props.fetchQuotation();
            props.closeModal();
            formQuotation.resetFields();
            formQuotation.setFieldsValue({
                margin: 0,
                tax: 0,
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingButton(false);
    }


    return (
        <Form layout="vertical" onFinish={storeQuotation} form={formQuotation} initialValues={{ margin: 0, tax: 0 }} requiredMark={false} className="mt-3">
            <QuotationForm
                btnTitle={"Submit"}
                loadingBtn={loadingButton}
                loading={false}
            />
        </Form>
    )


}

export default QuotationCreate;