/* Import Component React */
import React, {useEffect, useCallback, useState} from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import {Card, Row, Col, ListGroup} from "react-bootstrap";
import {Button, Form, message, Space} from "antd";

/* Import form */
import {StockForm, Info} from "./StockForm";

/* Time management variable */
import moment from "moment/moment";
import dayjs from "dayjs";
const dateNow = moment().format('Y-M-D');

/* Return the module */
function StockCreate() {

    /* Handle state and variable */
    const [formRef] = Form.useForm(); //For reference form
    const [messageApi, contextHolder] = message.useMessage(); //Handle message show

    //State document form
    const [barang, setBarang] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [dataBarang, setDataBarang] = useState([]);
    const [loadingBarang, setLoadingBarang] = useState(false); //Handle loading
    const [loadingSupplier, setLoadingSupplier] = useState(false); //Handel loading

    /* Handle notification */
    //Error message
    const errorStore = (errMsg) => {
        messageApi.open({
            type: 'error',
            content: errMsg,
        });
    }

    //Set default value
    const setDefaultValue = () => {
        formRef.setFieldsValue({
            tanggal_masuk: dayjs(dateNow),
            qty_fill: 1,
        });
    }

    //First render
    /*eslint-disable*/
    useEffect(() => {
        setDefaultValue(); //Set default value
        fetchSupplier().finally(); //Get list select supplier
        fetchBarang().finally(); //Get list select barang
    }, []);


    /* Handle connection, data and action */
    //Processing store document
    const store = async (values) => {
        //Send data to backend
        await Api.post('/stock', {
            barang_id: values.barang_id,
            supplier_id: values.supplier_id,
            qty_in: values.qty_in,
            qty_fill:  values.qty_fill,
            harga_beli: values.harga_beli,
            tanggal_masuk: dayjs(values.tanggal_masuk).format('YYYY/MM/DD'),
            created_by: localStorage.getItem('uuid_user'),
        }).then(() => {
            message.success("Stock is saved");
            onReset();
        }).catch((error) => {
            errorStore("Invalid server error, please try again");
        });
    }

    /* Form Handle */
    //Fetch data supplier for select
    async function fetchSupplier() {
        //Await
        setLoadingSupplier(true);
        await Api.get('/supplier/select').then(response => {
            setSupplier(response.data.data);
            setLoadingSupplier(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            setSupplier([]);
        });
    }

    //Fetch data barang for select
    async function fetchBarang() {
        //Await
        setLoadingBarang(true);
        await Api.get('/data-barang/select').then(response => {
            setBarang(response.data.data);
            setLoadingBarang(false);
        }).catch(() => {
            message.error("Invalid server error, please try again");
            setBarang([]);
        });
    }

    //Handle select supplier
    const listSupplier = useCallback( () => {
        fetchSupplier().finally();
        /* eslint-disable */
    }, []);

    //Handle select barang
    const listBarang = useCallback(async () => {
        fetchBarang().finally();
        /* eslint-disable */
    }, [barang]);

    //Fetch data barang for info
    const fetchDataBarang = useCallback(async (value) => {
        await Api.get(`/data-barang/index/${value}`).then(response => {
            setDataBarang(response.data.data);
            //Assign to field
            formRef.setFieldsValue({
                satuan: response.data.data.satuan,
            });
        }).catch(() => {
            message.error("Invalid server error, please try again");
            setDataBarang([]);
        });

    }, [barang]);

    //Reset form
    const onReset = () => {
        formRef.resetFields();
        setDefaultValue();
        setDataBarang([]);
    }

    return (
        <Row className="justify-content-md-center">
            <AuthVerify />
            {contextHolder}
            <Col xs sm lg={8}>
                <Card>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">
                            Entry Stock
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form layout="vertical" onFinish={store} form={formRef} initialValues={{ remember: true }} requiredMark={false} className="mt-3">
                                <StockForm
                                    supplier={listSupplier} loadingSupplier={loadingSupplier} optionsSupplier={supplier}
                                    barang={listBarang} satuan={fetchDataBarang} loadingBarang={loadingBarang} optionsBarang={barang}
                                />

                                <hr className="my-3" />
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <Button onClick={onReset} htmlType="button">
                                            Reset
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                         </ListGroup.Item>
                     </ListGroup>
                 </Card>
             </Col>

            <Col xs sm lg={4}>
                <Card>
                    <Info dataBarang={dataBarang}/>
                </Card>
            </Col>
        </Row>
    );
}

export default StockCreate;