/* Import component react */
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import style */
import {Card} from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import {Button, message, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRecycle, faTrash} from "@fortawesome/free-solid-svg-icons";

/* Main module */
function SaleHistoryTrash() {
    /* Handle state */
    const [saleTrash, setSaleTrash] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const navigate = useNavigate();

    /* Handle table */
    /*eslint-disable*/
    const columns = useMemo(() => [
        {
            accessorKey: "nomor_invoice",
            header: "Invoice",
        },
        {
            accessorKey: "customer",
            header: "Customer",
        },
        {
            accessorKey: "tanggal_penjualan",
            header: "Transaction Date",
        },
        {
            accessorKey: "due_date",
            header: "Due Date",
        },
        {
            accessorKey: "selling_type",
            header: "Type",
        },
        {
            accessorKey: "payment_status",
            header: "Payment Status",
        },
        {
            accessorKey: "total_barang",
            header: "Total Item",
        },
        {
            accessorKey: "quantity",
            header: "Qty",
        },
        {
            accessorKey: "total_harga",
            header: "Total",
        },
        {
            accessorKey: "payment_received",
            header: "Payment Received",
        },
        {
            accessorKey: "bill",
            header: "Bill",
        }
    ]);


    /*
    * Fetch data in first render
    */
    useEffect(() => {
        fetchData().finally();
    }, []);


    /*
    * Get data form backend using api.
    */
    async function fetchData() {
        setLoadingTable(true);
        await Api.get('/index-sale-trash').then(response => {
            setSaleTrash(response.data.data);
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingTable(false);
    }


    /*
    * Delete sale history.
    */
    const confirmDelete = async (uuid, nomor_invoice) => {
        await Api.delete(`/index-sale/${uuid}`).then(response => {
            message.success(`Sale ${nomor_invoice} deleted`);
            console.log(response);
            fetchData();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    /*
    * Restore sale from trash.
    */
    const confirmRestoreSale = async (uuid, nomor_invoice) => {
        await Api.get(`/index-sale-restore/${uuid}`).then(response => {
            message.success(`Sale ${nomor_invoice} is restored`);
            console.log(response);
            fetchData();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
    }


    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loadingTable }}
                columns={columns}
                data={saleTrash}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'nomor_invoice', 'customer'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigate(`/inventory/sale/detail/${row.original.nomor_invoice}`);
                    },
                })}
                renderRowActions={({ row}) => (
                    <>
                        <Popconfirm
                            title="Restore"
                            description="Are you sure to restore this?"
                            onConfirm={() => confirmRestoreSale(row.original.uuid, row.original.nomor_invoice)}
                            onCancel={() => message.error('Restore Canceled')}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small"> <FontAwesomeIcon icon={faRecycle} /> </Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Delete"
                            description="Are you sure to delete this?"
                            onConfirm={() => confirmDelete(row.original.uuid, row.original.nomor_invoice)}
                            onCancel={() => message.error('Delete Canceled')}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small"> <FontAwesomeIcon icon={faTrash} /> </Button>
                        </Popconfirm>
                    </>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '450px',
                        maxHeight: '650px'
                    }
                }}
            />
        </Card>
    )
}

export default SaleHistoryTrash;