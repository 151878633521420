/* Import Component React */
import React, { useEffect, useMemo, useState } from "react";
import Api from "../Api";
import AuthVerify from "../AuthVerify";

/* Import Style */
import { Card } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {DatePicker, Space} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

/* Import Component Export */
import ReportSaleToExcel from "./ReportSaleToExcel";

/* Main Component */
function ReportSaleCustomer() {
    //Prepare variable
    const companyName = localStorage.getItem('company_name');
    const [sale, setSale] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataExport, setDataExport] = useState([]);
    const fileName = `${companyName} - Sale Customer`;
    const { RangePicker } = DatePicker;

    /* Handle table */
    /*eslint-disable*/
    const columns = useMemo(() => [
        {
            accessorKey: "nomor_invoice",
            header: "Invoice",
        },
        {
            accessorKey: "customer",
            header: "Customer",
        },
        {
            accessorKey: "tanggal_penjualan",
            header: "Transaction Date",
        },
        {
            accessorKey: "due_date",
            header: "Due Date",
        },
        {
            accessorKey: "selling_type",
            header: "Type",
        },
        {
            accessorKey: "payment_status",
            header: "Payment Status",
        },
        {
            accessorKey: "total_barang",
            header: "Total Item",
        },
        {
            accessorKey: "quantity",
            header: "Qty",
        },
        {
            accessorKey: "total_harga",
            header: "Total",
        },
        {
            accessorKey: "payment_received",
            header: "Payment Received",
        },
        {
            accessorKey: "bill",
            header: "Bill",
        }
    ]);



    //Hook data in first rendering
    useEffect(() => {
        fetchDataPeriod().finally();
    }, []);

    /*
    * Handle report period.
    */
    const handleReportPeriod = (values) => {
        fetchDataPeriod(values).finally();
    }

    //Connection to api to get data
    async function fetchDataPeriod(values) {
        //Prepare variable
        const startDate = values ? dayjs(values[0]).format('YYYY-MM-DD') : '0';
        const endDate = values ? dayjs(values[1]).format('YYYY-MM-DD') : '0';

        setLoading(true);
        await Api.get(`/report-sale-period/${startDate}/${endDate}`).then(response => {
            setSale(response.data.data);
            // Reshaping the array
            const headings = response.data.data.map( (item, index) => ({
                "No": index+1,
                "Nomor Invoice": item.nomor_invoice,
                "Customer": item.customer,
                "Transaction Date": item.tanggal_penjualan,
                "Due Date": item.due_date,
                "Selling Type": item.selling_type,
                "Payment Status": item.payment_status,
                "Total Item": item.total_barang,
                "Quantity": item.quantity,
                "Total": item.total_harga,
                "Payment Received": item.payment_received,
                "Bill": item.bill,
            }));
            setDataExport(headings);
        }).catch(() => {
            setSale([]);
        });
        setLoading(false);
    }

    return (
        <Card className="card-body">
            <AuthVerify />
            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={sale}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact', columnPinning: { left: ['mrt-row-numbers', 'nomor_barang', 'nama_barang'] }  }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '600px'
                    }
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <Space sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                        <RangePicker placeholder={ ['Start date', 'End date'] } locale={locale} onChange={handleReportPeriod}/>
                        <ReportSaleToExcel apiData={dataExport} fileName={fileName}/>
                    </Space>
                )}
            />
        </Card>
    );
}

export default ReportSaleCustomer;