/* Import component */
import React, {useEffect, useState} from "react";
import Api from "../Api";

/* Import Style */
import {Form, message, Skeleton} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/* Import child component */
import DeliveryForm from "./DeliveryForm";

/* Main component */
function DeliveryUpdate(props) {
    const userInfo = props.userInfo;
    const [formDelivery] = Form.useForm(); //For reference form delivery
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);


    /*
    * Set focus in form
    */
    useEffect(() => {
        fetchDelivery().finally();
        /*eslint-disable*/
    }, []);


    /*
    * Fetch data for update delivery.
    */
    async function fetchDelivery() {
        setLoading(true);
        await Api.get(`/do/${props.uuidDo}`).then(response => {
            const data = response.data.data;
            formDelivery.setFieldsValue({
                customer_id: { value: data.destination_id, label: data.destination },
                invoice_id: { value: data.invoice_id, label: data.invoice_number},
                delivery_date: dayjs(data.delivery_date),
                delivery_via: data.delivery_via,
                delivery_cost: data.delivery_cost,
                remark: data.remark,
                send_by: { value: data.send_by_id, label: data.send_by},
                acknowledged_by: { value: data.acknowledged_by_id, label: data.acknowledged_by }
            });
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoading(false);
    }


    /*
    * Handle update delivery order.
    */
    const updateDelivery = async (values) => {
        setLoadingButton(true);
        //Prepare value options
        let invoice_id, customer_id, send_by, acknowledged_by;
        values.invoice_id.value ? (invoice_id = values.invoice_id.value) : (invoice_id = values.invoice_id);
        values.customer_id.value ? (customer_id = values.customer_id.value) : (customer_id = values.customer_id);
        values.send_by.value ? (send_by = values.send_by.value) : (send_by = values.send_by);
        values.acknowledged_by.value ? (acknowledged_by = values.acknowledged_by.value) : (acknowledged_by = values.acknowledged_by);


        await Api.post(`/do/${props.uuidDo}`, {
            company_id: userInfo.companyId,
            delivery_date: dayjs(values.delivery_date).format('YYYY-MM-DD'),
            invoice_id: invoice_id,
            origin_id: userInfo.companyId,
            destination_id: customer_id,
            delivery_via: values.delivery_via,
            delivery_cost: values.delivery_cost,
            remark: values.remark,
            created_by: userInfo.userId,
            send_by: send_by,
            acknowledged_by: acknowledged_by,
            _method: 'PUT',
        }).then(response => {
            console.log(response);
            message.success("Delivery order updated");
            props.fetchDelivery();
            props.closeModal();
            formDelivery.resetFields();
            fetchDelivery();
        }).catch(error => {
            console.log(error);
            message.error("Invalid server error");
        });
        setLoadingButton(false);
    }


    return (
        <Skeleton active loading={loading}>
            <Form layout="vertical" onFinish={updateDelivery} form={formDelivery} initialValues={{ delivery_cost: 0 }} requiredMark={false} className="mt-3">
                <DeliveryForm
                    btnTitle={"Update"}
                    loadingBtn={loadingButton}
                />
            </Form>
        </Skeleton>
    )


}

export default DeliveryUpdate;