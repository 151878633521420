/* Import component React */
import React, {useState} from "react";
import {Routes, Route, Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Api from './Api';

/* Import style */
import {Container, Nav, Navbar, Offcanvas, ThemeProvider} from "react-bootstrap";

/* Import component */
import UserMenu from "./Authentication/UserMenu";
import Dashboard from "./Dashboard";
import AuthVerify from "./AuthVerify";
import ManufactureNav from "./Manufacture/ManufactureNav";
import UomNav from "./Uom/UomNav";
import KategoriNav from "./Kategori/KategoriNav";
import SubKategoriNav from "./SubKategori/SubKategoriNav";
import JenisBarangNav from "./JenisBarang/JenisBarangNav";
import BarangNav from "./Barang/BarangNav";
import UsersNav from "./Authentication/UsersNav";
import CompanyNav from "./Company/CompanyNav";
import SupplierNav from "./Supplier/SupplierNav";
import StockNav from "./stock/StockNav";
import SaleNav from "./Sale/SaleNav";
import CustomerNav from "./Customer/CustomerNav";
import DeliveryNav from "./DeliveryOrder/DeliveryNav";
import QuotationNav from "./Quotation/QuotationNav";
import ReportStockNav from "./ReportStock/ReportStockNav";
import ReportSaleNav from "./ReportSale/ReportSaleNav";


function InventoryDash() {
    //Defined variable

    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const user = localStorage.getItem('name');
    const company_name = localStorage.getItem('company_name');
    const company_logo = localStorage.getItem('company_logo');

    /* Handle navbar off canvas */
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Logout Handler
    const logoutHandler = async () => {
        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        //Await
        await Api.post('/logout').then(() => {
            //Remove token
            localStorage.removeItem("token");
            localStorage.removeItem("uuid_user");
            localStorage.removeItem("name");
            localStorage.removeItem("level");
            localStorage.removeItem("company_id");
            localStorage.removeItem("company_name");
            localStorage.removeItem("company_logo");
            //Redirect to log in
            navigate('/')
        }).catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("uuid_user");
            localStorage.removeItem("name");
            localStorage.removeItem("level");
            localStorage.removeItem("company_id");
            localStorage.removeItem("company_name");
            localStorage.removeItem("company_logo");
        });
    }

    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >
            <div className="App">
                <AuthVerify />
                <header className="sticky-top-header">
                    <Navbar collapseOnSelect expand="lg" className="main-navbar shadow">
                        <Container fluid>
                            <Navbar.Brand href="/">
                                {
                                    company_logo === "undefined" ? (
                                        <img
                                            alt=""
                                            src="/logoAJG.png"
                                            width="30"
                                            height="25"
                                            className="d-inline-block align-top"
                                        />
                                    ) : (
                                        <img
                                            alt=""
                                            src={company_logo}
                                            width="30"
                                            height="25"
                                            className="d-inline-block align-top"
                                        />
                                    )
                                }{' '}
                                {
                                    company_name === "undefined" ? (
                                        <span className="h5 text-light">Enterprise Resource Planning</span>
                                    ) : (
                                        <span className="h5 text-light">{company_name}</span>
                                    )
                                }
                            </Navbar.Brand>
                            <Navbar.Text>
                                <Nav.Link as={Link} to="#" style={{ textDecoration: "none"}} className="text-light"> { user } </Nav.Link>
                            </Navbar.Text>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-lg-none d-md-none" onClick={handleShow}/>
                        </Container>
                    </Navbar>
                </header>

                <div className="container-fluid">
                    <div className="row">
                        <Offcanvas show={show} onHide={handleClose} responsive="md lg" className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <h5>Company name</h5>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <UserMenu
                                logout={logoutHandler}
                            />
                        </Offcanvas>

                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                            <Routes>
                                <Route path="/*" element={<Dashboard />} />
                                <Route path="stock/*" element={<StockNav />} />
                                <Route path="manufacture/*" element={<ManufactureNav/>} />
                                <Route path="uom/*" element={<UomNav />} />
                                <Route path="kategori/*" element={<KategoriNav />} />
                                <Route path="sub-kategori/*" element={<SubKategoriNav />} />
                                <Route path="jenis-barang/*" element={<JenisBarangNav />} />
                                <Route path="data-barang/*" element={<BarangNav />} />
                                <Route path="user/*" element={<UsersNav />} />
                                <Route path="company/*" element={<CompanyNav />} />
                                <Route path="supplier/*" element={<SupplierNav />} />
                                <Route path="customer/*" element={<CustomerNav />} />
                                <Route path="sale/*" element={<SaleNav />} />
                                <Route path="delivery/*" element={<DeliveryNav />} />
                                <Route path="quotation/*" element={<QuotationNav />} />
                                <Route path="report-stock/*" element={<ReportStockNav />} />
                                <Route path="report-sale/*" element={<ReportSaleNav />} />
                                {/*<Route path="/storage-location/*" element={<StorageLocationNav />} />*/}
                            </Routes>
                        </main>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default InventoryDash;
