/* Import Component React*/
import {ButtonGroup, Button, ButtonToolbar} from "react-bootstrap";
import {Link, Route, Routes, useLocation} from "react-router-dom";

/* Import component module */
import ManufactureView from "./ManufactureView";
import ManufactureViewTrash from "./ManufactureViewTrash";
import ManufactureCreate from "./ManufactureCreate";
import ManufactureUpdate from "./ManufactureUpdate";

/* Return the module*/
function ManufactureNav() {
    //Location path and active button
    const location = useLocation();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4">Manufacture</h4>
                <ButtonToolbar>
                    <ButtonGroup className="me-2">
                        <Button as={Link}  to="/inventory/manufacture" eventKey="/inventory/manufacture" active={location.pathname === "/inventory/manufacture"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Data
                        </Button>
                        <Button as={Link} to="/inventory/manufacture/create" eventKey="/inventory/manufacture/create" active={location.pathname === "/inventory/manufacture/create"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Create
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button as={Link} to="/inventory/manufacture/trash" eventKey="/inventory/manufacture/trash" active={location.pathname === "/inventory/manufacture/trash"} variant="outline-secondary" size="sm" className="btn-navigation">
                            Trash
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Routes>
                <Route path="/" element={<ManufactureView/>} />
                <Route path="create" element={<ManufactureCreate/>} />
                <Route path="update/:id" element={<ManufactureUpdate/>} />
                <Route path="trash" element={<ManufactureViewTrash/>} />
            </Routes>
        </>
    );
}

export default ManufactureNav;