/* Import component react */
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";


const AuthVerify = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const currentDate = new Date();

    /*eslint-disable*/
    useEffect(() => {

        //Checking if token exist
        if(token){
            const decodeToken = jwtDecode(token); //Decode token exp
            //Checking token if expired
            if (decodeToken.exp * 1000 < currentDate.getTime()){
                localStorage.removeItem("token");
                localStorage.removeItem("uuid_user");
                localStorage.removeItem("name");
                localStorage.removeItem("level");
                localStorage.removeItem("company_id");
                localStorage.removeItem("company_name");
                localStorage.removeItem("company_logo");
                navigate('/');
            }
        } else {
            navigate('/');
        }

    }, []);
};

export default AuthVerify;